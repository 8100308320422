import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import "./layout.scss"
import { Link } from "gatsby"
import { isHCP } from "../helpers/helpers"

const Footer = ({ footerLinks, libCode }) => {
  const [currentSite, setCurrentSite] = useState("")
  const [currentLib, setCurrentLib] = useState("")
  const [libDate, setLibDate] = useState("")

  let libs

  useEffect(() => {
    if (isHCP()) {
      setCurrentSite("/hcp")
    }

    if (currentSite === "/hcp") {
      libs = libCode[0].content
    } else {
      libs = libCode[1].content
    }

    setCurrentLib(libs[0].code)
    setLibDate(libs[0].date)
  }, [currentSite, libs])

  const footerItems = footerLinks.map((item, index) =>
    index === 2 ? (
      <li key={index}>
        <Link
          to={currentSite + item.link}
          data-gtm-event-label={item.eventLabel}
          className={item.eventClass}
        >
          Site Map
        </Link>
      </li>
    ) : (
      <li key={index}>
        <a
          href={item.link}
          className={item.eventClass}
          data-gtm-event-label={item.eventLabel}
          target="_blank"
          rel="noreferrer"
        >
          {item.name}
        </a>
      </li>
    )
  )

  return (
    <>
      <footer>
        <div className="row">
          <div className="small-12 medium-12 column">
            <nav>
              <ul>{footerItems}</ul>
            </nav>
          </div>
        </div>
        <div className="row align-bottom">
          <div className="small-12 medium-10 column pb-2 small-order-1 medium-order-1 large-order-1">
            <p>
              Recorlev<sup>&reg;</sup>, Xeris Pharmaceuticals<sup>&reg;</sup>,{" "}
              <br className="hide-for-large" />
              Xeris CareConnection<sup className="trade">&trade;</sup>, and
              their associated logos are trademarks owned by or licensed to
              Xeris Pharmaceuticals, Inc.
              <br />
              PANTHERx Rare Pharmacy is a service mark of PANTHERx Rare, LLC.
              <br />
              Copyright &copy; {new Date().getFullYear()} Xeris Pharmaceuticals,
              Inc. All rights reserved. {currentLib} &nbsp; {libDate}
            </p>
          </div>
          <div className="small-12 medium-2 column small-order-2 py-2 medium-order-1 large-order-2 text-center">
            <a
              href="https://www.xerispharma.com/"
              target="_blank"
              rel="noreferrer"
              className="gtm-footer"
              data-gtm-event-label="Xeris Logo"
            >
              <div className="d-flex flex-dir-column align-bottom">
                <StaticImage
                  src="../images/xeris-pharma-logo.png"
                  alt="Links to Xeris Pharmaceuticals Website"
                  placeholder="blurred"
                  layout="constrained"
                  width={181}
                />
              </div>
            </a>
          </div>
        </div>
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/45850190.js"></script>
      </footer>
    </>
  )
}

Footer.propTypes = {
  children: PropTypes.node,
}

export default Footer
