import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/template-partials/global-components/section"
import InteriorPageHeader from "../components/template-partials/global-components/interior-page-header"
import Button from "../components/template-partials/global-components/buttons"
import Card from "../components/template-partials/global-components/cards"

const AboutCushings = () => (
  <Layout>
    <Seo
      title="About Cushing’s Syndrome"
      description="Learn about the importance of controlling cortisol levels. Please see Important Safety Information and full
      Prescribing Information, including Boxed Warning, on this website."
      ogdescription="Learn about the importance of controlling cortisol levels. Please see Important Safety Information and full
      Prescribing Information, including Boxed Warning, on this website."
    />

    <InteriorPageHeader alt="About Cushing's">
      <h1>About Cushing's</h1>
    </InteriorPageHeader>
    <Section className="interior-page">
      <div className="row">
        <div className="small-12 column">
          <p>
            Endogenous Cushing’s syndrome is a rare condition caused by too much
            cortisol in your body. “Endogenous” means that something inside your
            body causes too much cortisol production.
          </p>

          <figure className="text-center pt-1 pb-2 md_pt-1 md_pb-2">
            <StaticImage
              className="show-for-large"
              src="../images/cushing-syndrome-pituitary-gland-callout.png"
              alt="The most common form of Cushing’s syndrome is Cushing’s disease, which is caused by a tumor in the
              pituitary gland (a gland under the brain that produces and controls hormones). When you see “Cushings’s” on this
              website, it includes both Cushing’s syndrome and Cushing’s disease."
              placeholder="blurred"
              layout="constrained"
              width={784}
            />
            <StaticImage
              className="hide-for-large"
              src="../images/cushing-syndrome-pituitary-gland-callout-mobile.png"
              alt="The most common form of Cushing’s syndrome is Cushing’s disease, which is caused by a tumor in the
              pituitary gland (a gland under the brain that produces and controls hormones). When you see “Cushings’s” on this
              website, it includes both Cushing’s syndrome and Cushing’s disease."
              placeholder="blurred"
              layout="constrained"
              width={486}
            />
          </figure>

          <p>
            If you have been diagnosed with Cushing’s and are experiencing
            symptoms, ask your doctor if Recorlev<sup>&reg;</sup>{" "}
            (levoketoconazole) may be right for you.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="small-12 column">
          <h4>
            Cortisol is a hormone made by the adrenal glands—located on top of
            the kidneys—that plays an important role in the way your body
            functions, including:
          </h4>
        </div>
      </div>

      <div className="row align-center">
        <div className="small-12 medium-4 xlarge-3 column">
          <Card
            CardClass={"__patients"}
            CardImgSrc={
              <StaticImage
                src="../images/icons/stress-icon.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={101}
              />
            }
            CardTitle={<strong>Responding to stress</strong>}
          ></Card>
        </div>
        <div className="small-12 medium-4 xlarge-3 column">
          <Card
            CardClass={"__patients"}
            CardImgSrc={
              <StaticImage
                src="../images/icons/blood-pressure-icon.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={127}
              />
            }
            CardTitle={<strong>Controlling blood pressure</strong>}
          ></Card>
        </div>
        <div className="small-12 medium-4 xlarge-3 column">
          <Card
            CardClass={"__patients"}
            CardImgSrc={
              <StaticImage
                src="../images/icons/protein-carbohydrates-fat-to-energy-icon.png"
                alt="Changing protein, carbohydrates, and fat into energy"
                placeholder="blurred"
                layout="constrained"
                width={132}
              />
            }
            CardTitle={
              <strong>
                Changing protein, carbohydrates, and fat into energy
              </strong>
            }
          ></Card>
        </div>
        <div className="small-12 column pt-3">
          <p>
            Irregular cortisol production can affect your entire body, including
            your bones and your immune, cardiovascular, and nervous systems.
          </p>
        </div>
      </div>

      <div className="row pt-1 pb-2 md_py-2">
        <div className="small-12 column text-center">
          <StaticImage
            src="../images/cushing-main-goal-text-box.png"
            alt="The main goal of treating Cushing’s is to get your cortisol levels back to normal"
            placeholder="blurred"
            layout="constrained"
            width={510}
          />
        </div>
      </div>

      <div className="row">
        <div className="small-12 column">
          <h3>Why is it important to control your cortisol levels?</h3>
          <h4>
            Too much cortisol in your body caused by Cushing’s can be difficult
            to live with and may affect your overall health and quality of life{" "}
          </h4>
          <h5>
            The impact of too much cortisol is both physical and emotional, and
            it may lead to:
          </h5>
        </div>
      </div>

      <div className="row align-center md_pb-2">
        <div className="small-12 medium-2 column">
          <Card
            CardClass={"__patients"}
            CardImgSrc={
              <StaticImage
                src="../images/icons/weight-gain-icon.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={114}
              />
            }
            CardTitle={<strong>Weight gain</strong>}
          ></Card>
        </div>
        <div className="small-12 medium-2 column">
          <Card
            CardClass={"__patients"}
            CardImgSrc={
              <StaticImage
                src="../images/icons/depression-and-feeling-irritable-icon.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={128}
              />
            }
            CardTitle={<strong>Depression and feeling irritable</strong>}
          ></Card>
        </div>
        <div className="small-12 medium-2 column">
          <Card
            CardClass={"__patients"}
            CardImgSrc={
              <StaticImage
                src="../images/icons/irregular-periods-icon.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={131}
              />
            }
            CardTitle={<strong>Irregular periods</strong>}
          ></Card>
        </div>
        <div className="small-12 medium-2 column">
          <Card
            CardClass={"__patients"}
            CardImgSrc={
              <StaticImage
                src="../images/icons/poor-concentration-icon.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={125}
              />
            }
            CardTitle={<strong>Poor concentration</strong>}
          ></Card>
        </div>
        <div className="small-12 medium-2 column">
          <Card
            CardClass={"__patients"}
            CardImgSrc={
              <StaticImage
                src="../images/icons/fatigue-icon.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={106}
              />
            }
            CardTitle={<strong>Fatigue</strong>}
          ></Card>
        </div>
        <div className="small-12 column pt-3 md_pt-1">
          <p>
            Talk to your doctor about the chances of an increase in testosterone
            from your medicine.
          </p>
        </div>
      </div>

      <div className="row py-2 lg_pt-1 lg_pb-3">
        <div className="column text-center">
          <Button
            ButtonLink={"/what-is-recorlev/"}
            ButtonClass={"primary gtm-cta"}
            ButtonText={"find out about the benefits of RECORLEV"}
            ButtonIconAfter={"&nbsp;&gt;"}
            EventLabel={"find out about the benefits of RECORLEV"}
          ></Button>
        </div>
      </div>
    </Section>
  </Layout>
)

export default AboutCushings
