import * as React from "react"
// import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import PatientForm from "../../components/template-partials/contact-form/patient-form"
//import Button from "../../components/template-partials/global-components/buttons"

const SignUpForSupport = () => (
  <Layout>
    <Seo
      title="Sign up for support | Recorlev® (levoketoconazole)"
      description="Get help and support from the Xeris CareConnection™ team. Please see Important Safety Information and full
      Prescribing Information, including Boxed Warning, on this website."
      ogdescription="Get help and support from the Xeris CareConnection™ team. Please see Important Safety Information and full
      Prescribing Information, including Boxed Warning, on this website."
    />
    <InteriorPageHeader alt="Sign Up For Support">
      <h1>Sign up for support</h1>
    </InteriorPageHeader>
    <Section className="interior-page">
      <div className="row">
        <div className="small-12 column">
          <PatientForm />
        </div>
      </div>
    </Section>
  </Layout>
)

export default SignUpForSupport
