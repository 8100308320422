import * as React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Section from "../../../components/template-partials/global-components/section"
import InteriorPageHeader from "../../../components/template-partials/global-components/interior-page-header"
import ContactForm from "../../../components/template-partials/contact-form/contact-form"

const XerisSupport = () => {
  return (
    <Layout>
      <Seo
        title="Connect with Xeris support | RECORLEV® (levoketoconazole)"
        description="Get in touch with Xeris support via email"
        ogdescription="Get in touch with Xeris support via email. See Important Safety Information, including BOXED WARNING."
        siteType="hcp"
      />
      <InteriorPageHeader alt="Connect with Xeris support">
        <h1>Connect with Xeris support</h1>
      </InteriorPageHeader>
      <Section className="interior-page">
        <div className="row">
          <div className="small-12 column">
            <ContactForm />
          </div>
        </div>
      </Section>
    </Layout>
  )
}
export default XerisSupport
