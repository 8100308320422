import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"

import headingBg from "../../images/banner-placeholder.svg"

import { hcpDetails } from "../../components/template-partials/isi/hcp-isi"

const ImportantSafetyInformation = () => {
  let isiText = hcpDetails.hcpIsiSummary + hcpDetails.hcpIsiFull

  return (
    <Layout>
      <Seo title="Important Safety Information" siteType="hcp" />
      <InteriorPageHeader
        backgroundImage={headingBg}
        alt="Important Safety Information"
      >
        {/* <h1>Indication &amp; Important Safety Information for Recorlev<sup>&reg;</sup> (levoketoconazole)</h1> */}
      </InteriorPageHeader>
      <Section className="interior-page">
        <div className="row">
          <div
            className="small-12 columns"
            dangerouslySetInnerHTML={{ __html: isiText }}
          ></div>
        </div>
      </Section>
    </Layout>
  )
}

export default ImportantSafetyInformation
