import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"

//import SitemapComponent from "../../components/template-partials/sitemap/site-map"

import PropTypes from "prop-types"

const Sitemap = () => (
  <Layout>
    <Seo
      title="Site Map"
      description="See the site map for the Recorlev HCP Website."
      ogdescription="See the site map for the Recorlev HCP Website. See Important Safety Information, including BOXED WARNING."
      siteType="hcp"
    />

    <Section className="interior-page">
      <div className="row md_pt-3">
        <div className="small-12 column">
          <h1>Site Map</h1>
        </div>
      </div>

      <div className="row">
        <div className="small-12 column">
          <nav className="sitemap">
            <ul>
              <li>
                <h2>
                  <Link
                    to="/hcp/"
                    className="gtm-link-internal"
                    data-gtm-event-label="Home"
                  >
                    Home
                  </Link>
                </h2>
              </li>
              <li>
                <h2 className="tc-black">Clinical studies</h2>
                <ul>
                  <li>
                    <h3>
                      <Link
                        to="/hcp/clinical-studies/logics/"
                        className="gtm-link-internal"
                        data-gtm-event-label="Logics"
                      >
                        LOGICS
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3>
                      <Link
                        to="/hcp/clinical-studies/sonics/"
                        className="gtm-link-internal"
                        data-gtm-event-label="Sonics"
                      >
                        SONICS
                      </Link>
                    </h3>
                  </li>
                </ul>
              </li>
              <li>
                <h2>
                  <Link
                    to="/hcp/dosing-and-monitoring/"
                    className="gtm-link-internal"
                    data-gtm-event-label="Dosing and monitoring"
                  >
                    Dosing and monitoring
                  </Link>
                </h2>
              </li>
              <li>
                <h2>
                  <Link
                    to="/hcp/safety-profile/"
                    className="gtm-link-internal"
                    data-gtm-event-label="Safety"
                  >
                    Safety
                  </Link>
                </h2>
              </li>
              <li>
                <h2>
                  <Link
                    to="/hcp/moa/"
                    className="gtm-link-internal"
                    data-gtm-event-label="MOA"
                  >
                    MOA
                  </Link>
                </h2>
              </li>
              <li>
                <h2 class="tc-black">Support</h2>
                <ul>
                  <li>
                    <h2>
                      <Link
                        to="/hcp/support/xeris-careconnection/"
                        className="gtm-link-internal"
                        data-gtm-event-label="Xeris CareConnection"
                      >
                        Xeris CareConnection<sup>&trade;</sup>
                      </Link>
                    </h2>
                  </li>
                  <li>
                    <h2>
                      <Link
                        to="/hcp/support/tools-and-resources/"
                        className="gtm-link-internal"
                        data-gtm-event-label="Resources for your practice"
                      >
                        Resources for your practice
                      </Link>
                    </h2>
                  </li>
                  <li>
                    <h2>
                      <Link
                        to="/hcp/support/connect-with-xeris-support/"
                        className="gtm-link-internal"
                        data-gtm-event-label="Connect with Xeris support"
                      >
                        Connect with Xeris support
                      </Link>
                    </h2>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Section>
  </Layout>
)

Sitemap.propTypes = {
  children: PropTypes.node,
}
export default Sitemap
