import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import Section from "../../components/template-partials/global-components/section"
import HeroPageHeader from "../../components/template-partials/homepage/hero-page-header"

import headingBg from "../../images/banner-placeholder.svg"
import Button from "../../components/template-partials/global-components/buttons"

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Recorlev® (levoketoconazole) | Official HCP Website"
        description="Find information about Recorlev® (levoketoconazole), including safety profile, efficacy data, dosing information, and
        how to get your patients started. See Important Safety Information, including BOXED WARNING."
        ogdescription="Find information about Recorlev® (levoketoconazole), a treatment option for Cushing’s syndrome. See Important Safety
        Information, including BOXED WARNING."
        siteType="hcp"
      />

      <HeroPageHeader backgroundImage={headingBg} alt="SONICS"></HeroPageHeader>
      <Section className="interior-page">
        <section className="bg-light home-buckets">
          <div className="row align-center text-center">
            <div className="columns small-12">
              <div className="row">
                <div className="columns small-12 large-4 pb-2 lg_pb-0 large-text-left">
                  <Button
                    ButtonLink={"/hcp/clinical-studies/logics/"}
                    ButtonClass={
                      "primary d-inline-flex align-middle h-100 gtm-cta"
                    }
                    ButtonText={
                      "See Efficacy Results From<br />2 Clinical Studies"
                    }
                    ButtonIconAfter={"&nbsp;&gt;"}
                    EventLabel={"See Efficacy Results From 2 Clinical Studies"}
                  ></Button>
                </div>
                <div className="columns small-12 large-4 pb-2 lg_pb-0">
                  <Button
                    ButtonLink={"/hcp/dosing-and-monitoring/"}
                    ButtonClass={
                      "primary d-inline-flex align-middle h-100 gtm-cta"
                    }
                    ButtonText={"Learn How To Dose Properly"}
                    ButtonIconAfter={"&nbsp;&gt;"}
                    EventLabel={"Learn How To Dose Properly"}
                  ></Button>
                </div>
                <div className="columns small-12 large-4 pb-2 lg_pb-0 large-text-right">
                  <Button
                    ButtonLink={"/hcp/support/xeris-careconnection/"}
                    ButtonClass={
                      "primary d-inline-flex align-middle h-100 gtm-cta"
                    }
                    ButtonText={"Get patients started now"}
                    ButtonIconAfter={"&nbsp;&gt;"}
                    EventLabel={"Get patients started now"}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="row">
          <div className="columns">
            <h1>Recorlev<sup>®</sup> effectively normalized and sustained mean UFC in most patients</h1>
          </div>
        </div> */}
      </Section>
    </Layout>
  )
}

export default IndexPage
