// import privacyPolicy from '../../../../static/Xeris-privacy-policy.pdf'
import medicationGuide from "../../../../static/recorlev-medication-guide.pdf"

let patientCookie =
  '<p>This website intends to use cookies to improve the site and your experience. By continuing to browse the website, you are agreeing to accept our use of cookies. Learn more about our <a href="https://www.xerispharma.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Statement</a>.</p>'

//Summary (This text appears twice, once for the summarry on collapsed ISI and below for the full page)
let patientIsiSummary = '<div class="columns small-12 medium-8 isiText">'

patientIsiSummary +=
  '<h1 style="margin-top: -.9rem;margin-bottom: 1rem;">INDICATION AND IMPORTANT SAFETY INFORMATION FOR Recorlev<sup>&reg;</sup> (levoketoconazole) tablets</h1>'

patientIsiSummary +=
  "<h2>What is the most important information I should know about Recorlev?</h2>"

patientIsiSummary +=
  "<strong>Recorlev can cause serious side effects, including:</strong>"

patientIsiSummary += "<ul>"
patientIsiSummary +=
  "<li><strong>Liver damage (hepatotoxicity).</strong> Hepatotoxicity can happen in people who take Recorlev. Some people who are treated with ketoconazole, a medicine like the active ingredient in Recorlev, had serious liver problems that required a liver transplant or led to death.<br/><strong>Call your healthcare provider right away if you have:</strong> pain on the upper right side of your stomach area associated with nausea, unusual fatigue, yellowing of your skin or the whites of your eyes, or unusual bruising or bleeding.<br/><strong>Recorlev should not be used if you have:</strong> cirrhosis, active or poorly controlled liver disease, frequent stones in your gallbladder, or history of liver problems due to use of a drug.</li>"
patientIsiSummary += "</ul>"

patientIsiSummary += "</div>"
// END Summary

// NOTE: 'What is Recorlev?' text appears 2x in this file; here for desktop and again below for mobile
patientIsiSummary +=
  '<div class="columns small-12 medium-4 hide-for-small show-for-medium isiText whatIsRecorlev">'

patientIsiSummary +=
  '<h2 style="font-size: 1.125rem; line-height: 1.5rem; text-transform: uppercase;">What is Recorlev?</h2>'

patientIsiSummary += "<ul>"
patientIsiSummary +=
  "<li>Recorlev is a prescription medicine used to treat high cortisol (endogenous hypercortisolemia) levels in adult patients with Cushing’s syndrome who cannot have surgery or who have had surgery that did not cure their Cushing’s syndrome.</li><li>It is not known if Recorlev is safe and effective for the treatment of fungal infections. Recorlev is not to be used for treatment of fungal infections.</li>"
patientIsiSummary += "</ul>"

patientIsiSummary += "</div>"

//Full Text

let patientIsiFull = '<div class="columns small-12 medium-8 isiText">'

// This is the summary text for the full ISI page
patientIsiFull += '<div class="isi-summary-for-page">'

patientIsiFull +=
  "<h2>What is the most important information I should know about Recorlev?</h2>"

patientIsiFull +=
  "<strong>Recorlev can cause serious side effects, including:</strong>"

patientIsiFull += "<ul>"
patientIsiFull +=
  "<li><strong>Liver damage (hepatotoxicity).</strong> Hepatotoxicity can happen in people who take Recorlev. Some people who are treated with ketoconazole, a medicine like the active ingredient in Recorlev, had serious liver problems that required a liver transplant or led to death.<br/><strong>Call your healthcare provider right away if you have:</strong> pain on the upper right side of your stomach area associated with nausea, unusual fatigue, yellowing of your skin or the whites of your eyes, or unusual bruising or bleeding.<br/><strong>Recorlev should not be used if you have:</strong> cirrhosis, active or poorly controlled liver disease, frequent stones in your gallbladder, or history of liver problems due to use of a drug.</li>"
patientIsiFull += "</ul>"

patientIsiFull += "</div>"
// END This is the summary text for the full ISI page

patientIsiFull +=
  "<p>Your healthcare provider will do liver tests before and during treatment with Recorlev.</p>"

patientIsiFull += "<ul>"
patientIsiFull +=
  "<li><strong>Heart rhythm problems (QT prolongation).</strong> Recorlev can cause a heart problem called QT interval prolongation, or QT prolongation. QT prolongation can cause irregular heartbeats that can be life threatening.<br/><strong>Call your healthcare provider right away if you:</strong> feel severe lightheadedness or if you faint during treatment with Recorlev.<br/>Low blood electrolyte levels of potassium and magnesium can increase your chances of QT prolongation during treatment with Recorlev.<br/>Your healthcare provider will check your heart with a test called an electrocardiogram (ECG) and do blood tests to check your blood electrolyte levels before and during treatment with Recorlev.<br/>Recorlev should not be taken with certain other medicines that cause QT prolongation. Talk to your healthcare provider about the medicines you are taking before you start taking Recorlev.</li>"
patientIsiFull += "</ul>"

patientIsiFull += "<ul>"
patientIsiFull +=
  '<li><strong>Low cortisol levels (adrenal insufficiency).</strong> Adrenal insufficiency is a condition in which the adrenal glands do not make enough steroid hormones, such as cortisol. Recorlev may cause adrenal function insufficiency by excessively lowering production of cortisol.<p class="subItem"><strong>Call your healthcare provider right away if</strong> you have one or more of the following symptoms, as these may be symptoms of reduced adrenal function: nausea or vomiting, unusual fatigue, unexplained stomach pain, loss of appetite, body aches, dizziness, low blood pressure, abnormal electrolyte levels, or low blood sugar.</p></li>'
patientIsiFull += "</ul>"

patientIsiFull +=
  "<p>Your healthcare provider will collect blood or urine samples to measure your cortisol.</p>"

// 'What is Recorlev?' for page
patientIsiFull += '<div class="hide-for-small isi-page-what-is">'
patientIsiFull +=
  '<h2 style="font-size: 1.125rem; line-height: 1.5rem;">What is Recorlev?</h2>'
patientIsiFull += "<ul>"
patientIsiFull +=
  "<li>Recorlev is a prescription medicine used to treat high cortisol (endogenous hypercortisolemia) levels in adult patients with Cushing’s syndrome who cannot have surgery or who have had surgery that did not cure their Cushing’s syndrome.</li><li>It is not known if Recorlev is safe and effective for the treatment of fungal infections. Recorlev is not to be used for treatment of fungal infections.</li>"
patientIsiFull += "</ul>"
patientIsiFull += "</div>"

// 'What is Recorlev?' for mobile
patientIsiFull += '<div class="show-for-small hide-for-medium">'
patientIsiFull +=
  '<h2 style="font-size: 1.125rem; line-height: 1.5rem;">What is Recorlev?</h2>'
patientIsiFull += "<ul>"
patientIsiFull +=
  "<li>Recorlev is a prescription medicine used to treat high cortisol (endogenous hypercortisolemia) levels in adult patients with Cushing’s syndrome who cannot have surgery or who have had surgery that did not cure their Cushing’s syndrome.</li><li>It is not known if Recorlev is safe and effective for the treatment of fungal infections. Recorlev is not to be used for treatment of fungal infections.</li>"
patientIsiFull += "</ul>"
patientIsiFull += "</div>"

patientIsiFull += "<h2>Do not take Recorlev if you:</h2>"

patientIsiFull += "<ul>"
patientIsiFull += "<li>have or have had liver problems.</li>"
patientIsiFull +=
  "<li>take certain other medicines that cause QT prolongation.</li>"
patientIsiFull +=
  "<li>have a history of certain heart problems which may include one of the following conditions: torsades de pointes, ventricular tachycardia, ventricular fibrillation, or long QT syndrome.</li>"
patientIsiFull +=
  "<li>are allergic to levoketoconazole, ketoconazole, or any of the ingredients in Recorlev, or take certain medicines that may affect how your liver works. If you are not sure if you take these medicines, ask your healthcare provider.</li>"
patientIsiFull += "</ul>"

patientIsiFull +=
  "<h2>Before taking Recorlev, tell your healthcare provider about all of your medical conditions, including if you:</h2>"

patientIsiFull += "<ul>"
patientIsiFull += "<li>have or have had liver problems.</li>"
patientIsiFull +=
  "<li>have any heart problems, including a condition called long QT syndrome.</li>"
patientIsiFull +=
  "<li>have a history of low blood levels of potassium or magnesium.</li>"
patientIsiFull += "<li>are pregnant or plan to become pregnant.</li>"
patientIsiFull += "<li>are breastfeeding or plan to breastfeed.</li>"
patientIsiFull += "</ul>"

patientIsiFull +=
  "<p><strong>Tell your healthcare provider about all the medicines you take</strong>, including prescription and over-the-counter medicines, vitamins, and herbal supplements. Recorlev and other medicines may affect each other causing side effects.</p>"

patientIsiFull += "<h2>How should I take Recorlev?</h2>"

patientIsiFull += "<ul>"
patientIsiFull +=
  "<li>Take Recorlev exactly as your healthcare provider tells you. Your healthcare provider will tell you how much Recorlev to take and when to take it.</li>"
patientIsiFull +=
  "<li>If you miss a dose of Recorlev, take the next dose at your regular scheduled time.</li>"
patientIsiFull +=
  "<li>If you take too much Recorlev, call your healthcare provider or go to the nearest hospital emergency room right away.</li>"
patientIsiFull += "</ul>"

patientIsiFull += "<h2>What should I avoid while taking Recorlev?</h2>"

patientIsiFull += "<ul>"
patientIsiFull +=
  "<li>Drinking alcohol to excess while taking Recorlev may increase your chances of having serious side effects.</li>"
patientIsiFull += "</ul>"

patientIsiFull += "<h2>What are the possible side effects of Recorlev?</h2>"
patientIsiFull +=
  '<strong class="tc-primary">Recorlev may cause serious side effects, including:</strong>'

patientIsiFull += "<ul>"
patientIsiFull +=
  "<li><strong>Hypersensitivity reactions</strong>. Serious allergic reactions can happen in people who take Recorlev. Call your healthcare provider right away, or visit an emergency center, if you get a rash, itching, hives, fever, swelling of the lips or tongue, chest pain, or have trouble breathing. These could be signs of a serious allergic reaction.</li>"
patientIsiFull +=
  "<li><strong>Decreased testosterone</strong>. Recorlev may lower testosterone levels in males (breast enlargement, erectile dysfunction) and females (low desire for sex and mood changes).</li>"
patientIsiFull += "</ul>"

patientIsiFull +=
  "<p><strong>The most common side effects of Recorlev include</strong> nausea/vomiting, low potassium, easy bleeding/easy bruising, high blood pressure, headache, liver injury, abnormal uterine bleeding, redness of the skin, fatigue, upset stomach, arthritis, upper respiratory infection, muscle pain, abnormal heart rhythm, back pain, sleep disturbances, and fluid retention.</p>"
patientIsiFull +=
  "<p>Recorlev may cause fertility problems, which may affect your ability to have children. Talk to your healthcare provider if you have concerns about fertility.</p>"
patientIsiFull +=
  "<p>These are not all the possible side effects of Recorlev.</p>"
patientIsiFull +=
  "<p>You are encouraged to report negative side effects of prescription <a href='https://www.law.cornell.edu/definitions/uscode.php?width=840&height=800&iframe=true&def_id=21-USC-3092384-263718883&term_occur=999&term_src=' target='_blank' rel='noreferrer' class='text-underline gtm-pdf'>drugs</a> to the FDA. Visit <a href='https://www.fda.gov/medwatch' target='_blank' rel='noreferrer' class='text-underline gtm-pdf'>www.fda.gov/medwatch</a>, or call 1–800-FDA-1088.</p>"

patientIsiFull +=
  '<p style="color:#00205b;"><strong>For more information, please see the <a href="https://www.recorlev.com/full-prescribing-information.pdf" target="_blank" rel="noreferrer"  class="text-underline gtm-pdf" data-gtm-event-label="Full Prescribing Information">Full Prescribing Information</a> and <a href="' +
  medicationGuide +
  '" target="_blank" rel="noreferrer" class="text-underline gtm-link-internal" data-gtm-event-label="Medication Guide">Medication Guide</a>, including Boxed Warning, for Recorlev.</strong></p>'

patientIsiFull += "</div>"

export const patientDetails = {
  patientIsiSummary,
  patientIsiFull,
  patientCookie,
}
