import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import IconList from "../../components/template-partials/global-components/icon-list"
import Card from "../../components/template-partials/global-components/cards"
import Button from "../../components/template-partials/global-components/buttons"

const MeetYourSupportTeam = () => (
  <Layout>
    <Seo
      title="Meet your support team | Recorlev® (levoketoconazole)"
      description="Meet your support team and learn more about the Xeris support program, Xeris CareConnection™. Please see Important
Safety Information and full Prescribing Information, including Boxed Warning, on this website."
      ogdescription="Meet your support team and learn more about the Xeris support program, Xeris CareConnection™. Please see Important
      Safety Information and full Prescribing Information, including Boxed Warning, on this website."
    />
    <InteriorPageHeader alt="Get one-on-one support from your team">
      <h1>Get one-on-one support from your team</h1>
    </InteriorPageHeader>
    <Section className="interior-page">
      <div className="row">
        <div className="small-12 column __xeris-cc-logo">
          <StaticImage
            className="show-for-large"
            src="../../images/xeris-logo.png"
            alt=""
            placeholder="blurred"
            layout="constrained"
            width={371}
          />
          <StaticImage
            className="hide-for-large"
            src="../../images/xeris-logo-mobile.png"
            alt=""
            placeholder="blurred"
            layout="constrained"
            width={361}
          />
        </div>
      </div>

      <div className="row md_mt-2">
        <div className="small-12 column">
          <h3>
            Your Xeris CareConnection<sup>&trade;</sup> team is a true partner
            focused on providing you with what you need throughout your
            treatment journey
          </h3>
          <p>
            Each member of your team is there every step of the way, going the
            extra mile by paving a clear path to help you regain control.{" "}
          </p>
          <p>
            Your Cushing's journey is ever-changing. With this understanding,
            our support team will strive to always work with your future in
            mind. Your team includes one-on-one support through a dedicated
            Patient Access Manager (PAM) and PANTHERx Rare Pharmacy.{" "}
          </p>
        </div>
      </div>

      <div className="row md_mt-2">
        <div className="small-12 column">
          <h2>
            Your PAM is someone who will focus on you. They will check in with
            you on a regular basis and will always be available by call, text,
            or email to:
          </h2>
        </div>
      </div>

      <div className="row md_mt-2">
        <div className="small-12 medium-6 column">
          <IconList
            IconImgSrc={
              <StaticImage
                src="../../images/icons/PAM-icon.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={99}
              />
            }
            ListTextArea={
              <>
                <ul className="mt-1">
                  <li>
                    Help you along your journey and answer any questions or
                    concerns you may have while taking Recorlev<sup>&reg;</sup>{" "}
                    (levoketoconazole)
                  </li>
                  <li>
                    Check in with you to see how you are doing with treatment
                    and connect you to a clinical pharmacist who specializes in
                    rare diseases
                  </li>
                  <li>
                    Connect you with patient advocacy organizations that offer
                    resources and support for those living with Cushing’s
                  </li>
                </ul>
              </>
            }
          ></IconList>

          <IconList
            IconImgSrc={
              <StaticImage
                src="../../images/icons/financial-assistance-and-insurance-icon.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={99}
              />
            }
            ListTextArea={
              <>
                <ul className="mt-1">
                  <li>
                    Help you understand your insurance benefits and answer
                    questions about financial assistance
                  </li>
                  <li>
                    Provide information if you don’t have health insurance and
                    need coverage for Recorlev
                  </li>
                </ul>
              </>
            }
          ></IconList>

          <IconList
            IconImgSrc={
              <StaticImage
                src="../../images/icons/co-pay-icon.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={107}
              />
            }
            ListTextArea={
              <>
                <ul className="mt-1 md_mt-2">
                  <li>
                    Help you pay as little as $0 with a co-pay if you are
                    eligible
                  </li>
                </ul>
              </>
            }
          ></IconList>
        </div>
        <div className="small-12 medium-6 column text-center mt-2 mb-3 md_pt-2">
          <StaticImage
            src="../../images/xeris-care-connection-text-box.png"
            alt="Xeris CareConnection™ offers a free, convenient pickup and drop-off of your 24-hour urine collection"
            placeholder="blurred"
            layout="constrained"
            width={419}
          />
        </div>
      </div>

      <div className="row">
        <div className="small-12 medium-6 column">
          <figure className="panther mb-2">
            <StaticImage
              src="../../images/pantherx-rare-logo.png"
              alt=""
              placeholder="blurred"
              layout="constrained"
              width={262}
            />
          </figure>

          <h3 className="d-block md_mt-1">
            First national pharmacy to hold an accredited distinction in rare
            disease
          </h3>
          <h2>PANTHERx Rare Pharmacy</h2>
          <ul>
            <li>The only pharmacy that carries Recorlev</li>
            <li>Delivers Recorlev directly to you</li>
            <li>
              Works with your doctor's office to get information needed for
              insurance coverage
            </li>
            <li>
              Will call you regularly to check in and have a clinical pharmacist
              who specializes in endocrinology products speak with you about how
              you're doing on Recorlev
            </li>
          </ul>
        </div>
        <div className="small-12 medium-6 column text-center my-3">
          <StaticImage
            src="../../images/patient-laptop-illustration.png"
            alt=""
            placeholder="blurred"
            layout="constrained"
            width={500}
          />
        </div>
      </div>

      <div className="row md_my-3 align-center">
        <div className="small-12 medium-5 column">
          <div className="callout-pill tc-primary bg-light">
            <strong>
              Have more questions? Call for more support at{" "}
              <a
                href="tel:18444447258"
                className="tc-primary tel-number gtm-link-internal"
                data-gtm-event-label="Call for more support at 1-844-444-RCLV (7258)"
              >
                1-844-444-RCLV (7258)
              </a>
            </strong>
          </div>
        </div>
      </div>

      <div className="row py-2 lg_pt-1 lg_pb-3 align-center">
        <div className="small-12 medium-5 column text-center">
          <Button
            ButtonLink={"/one-on-one-support/sign-up-for-support/"}
            ButtonClass={"primary gtm-cta"}
            ButtonText={"Sign Up To Connect With A True Partner In Care"}
            ButtonIconAfter={"&nbsp;&gt;"}
            EventLabel={"Sign Up To Connect With A True Partner In Care"}
          ></Button>
        </div>
      </div>
    </Section>
  </Layout>
)

export default MeetYourSupportTeam
