import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import Button from "../../components/template-partials/global-components/buttons"
import IconList from "../../components/template-partials/global-components/icon-list"

const DosingMonitoring = () => (
  <Layout>
    <Seo
      title="Dosing &amp; Monitoring | RECORLEV® (levoketoconazole)"
      description="Learn the optimal dosing and monitoring guidance to follow while your patients take Recorlev"
      ogdescription="Learn the optimal dosing and monitoring guidance to follow while your patients take Recorlev, See Important Safety Information, including BOXED WARNING"
      siteType="hcp"
    />
    <InteriorPageHeader alt="Dosing and Monitoring">
      <h1>
        Optimal dosing and monitoring guidance<sup>1</sup>
      </h1>
    </InteriorPageHeader>

    <Section className="interior-page">
      <div className="row">
        <div className="column">
          <h2>Dosing guidelines</h2>
        </div>
      </div>

      <div className="row">
        <div className="columns text-center large-text-left">
          <StaticImage
            className="show-for-large"
            src="../../images/recorlev-dosing-and-monitoring-guidelines-steps.png"
            alt="1. Initiate: 150 mg BID taken with or without food 2. Titrate: individualized to achieve UFC normalization 3. Maintain: flexible maintenance dosing"
            placeholder="blurred"
            layout="constrained"
            width={1000}
          />
          <StaticImage
            className="hide-for-large"
            src="../../images/recorlev-dosing-and-monitoring-guidelines-steps-mobile.png"
            alt="1. Initiate: 150 mg BID taken with or without food 2. Titrate: individualized to achieve UFC normalization 3. Maintain: flexible maintenance dosing"
            placeholder="blurred"
            layout="constrained"
            width={500}
          />
        </div>
      </div>

      <div className="row">
        <div className="column">
          <h2>Clinically driven monitoring</h2>
        </div>
      </div>

      <div className="row large-collapse align-center">
        <div className="small-12 medium-12 large-8 xlarge-6 column">
          <IconList
            IconImgSrc={
              <StaticImage
                src="../../images/icons/initiating-treatment-icon.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={67}
              />
            }
            ListTitle={
              <>
                <h3>Before initiating treatment</h3>
              </>
            }
            ListTextArea={
              <>
                <ul>
                  <li>
                    Conduct baseline liver tests (ALT, AST, and total bilirubin)
                  </li>
                  <li>Obtain a baseline ECG</li>
                  <li>Correct hypokalemia and hypomagnesemia</li>
                </ul>
              </>
            }
          ></IconList>

          <IconList
            IconImgSrc={
              <StaticImage
                src="../../images/icons/monitoring-icon.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={75}
              />
            }
            ListTitle={
              <>
                <h3>Continued monitoring</h3>
              </>
            }
            ListTextArea={
              <>
                <ul>
                  <li>
                    Conduct an ECG before each dose increase. After a stable
                    dosage is established, monitor routinely for an effect on
                    the QT interval
                  </li>
                  <li>
                    Monitor 24-hour UFC, morning serum or plasma cortisol, and
                    patient’s signs and symptoms for hypocortisolism
                    periodically during Recorlev<sup>&reg;</sup> treatment
                  </li>
                </ul>
              </>
            }
          ></IconList>

          <IconList
            IconImgSrc={
              <StaticImage
                src="../../images/icons/liver-icon.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={85}
              />
            }
            ListTitle={
              <>
                <h3>Liver tests</h3>
              </>
            }
            ListTextArea={
              <>
                <ul className="mb-1 md_mb-1">
                  <li>
                    Serious hepatotoxicity has been reported in patients
                    receiving Recorlev, and therefore frequent monitoring of
                    liver tests is recommended
                  </li>
                  <li>
                    Monitor liver enzymes and bilirubin weekly for at least 6
                    weeks after starting Recorlev, every 2 weeks for the next 6
                    weeks, monthly for the next 3 months, and then as clinically
                    indicated
                  </li>
                  <li>
                    After any dose interruption or dose increase, monitor on a
                    weekly basis until a stable dosage is achieved
                  </li>
                </ul>
                <p>
                  See{" "}
                  <a
                    className="text-underline"
                    href="https://www.recorlev.com/full-prescribing-information.pdf"
                    class="gtm-pdf"
                    target="_blank"
                    rel="noreferrer"
                    data-gtm-event-label="Full Prescribing Information"
                  >
                    Full Prescribing Information
                  </a>{" "}
                  for dosage management and modification across AST/ALT and
                  total bilirubin values.{" "}
                </p>
              </>
            }
          ></IconList>
        </div>
      </div>

      <div className="row md_py-3 lg_py-3 align-center _contact-shape">
        <div className="small-12 medium-12 large-8 xlarge-6 column text-center p-relative">
          <StaticImage
            src="../../images/contact-shape.png"
            alt="contact-shape"
            placeholder="blurred"
            layout="constrained"
            width={618}
          />
          <div className="contact-shape-text p-absolute">
            <p>
              A clinical pharmacist is available to support you in the
              management of monitoring and dose modifications.
            </p>
            <p>
              <strong>
                Call{" "}
                <a
                  href="tel:18444447258"
                  class="gtm-link-internal"
                  data-gtm-event-label="1-844-444-RCLV (7258)"
                >
                  1-844-444-RCLV (7258)
                </a>{" "}
                <span>for support</span>
              </strong>
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="column">
          <p className="footnote">
            ALT=alanine aminotransferase; AST=aspartate aminotransferase;
            BID=twice daily; ECG=electrocardiogram; UFC=urinary free cortisol.
          </p>
          <p className="footnote">
            <b>Reference:</b> <b>1.</b> Recorlev. Prescribing Information. Xeris
            Pharmaceuticals, Inc; 2021.
          </p>
        </div>
      </div>

      <div className="row py-2 lg_pt-1 lg_pb-3">
        <div className="column text-center">
          <Button
            ButtonLink={"/hcp/safety-profile/"}
            ButtonClass={"primary gtm-cta"}
            ButtonText={"Proven Safety and Tolerability Profile Ahead"}
            ButtonIconAfter={"&nbsp;&gt;"}
            EventLabel={"Proven Safety and Tolerability Profile Ahead"}
          ></Button>
        </div>
      </div>
    </Section>
  </Layout>
)

export default DosingMonitoring
