import React, { useEffect, useState } from "react"
import LayoutFourOFour from "../components/layout-404"
import Seo from "../components/seo"

import Button from "../components/template-partials/global-components/buttons"

const NotFoundPage = () => {
  const [isHcp, setIsHcp] = useState(false)
  const [currentSite, setCurrentSite] = useState("")

  useEffect(() => {
    let windowLoc = window.location.href
    if (windowLoc.includes("/hcp/")) {
      setIsHcp(true)
      setCurrentSite("hcp")
    } else {
      setIsHcp(false)
      setCurrentSite("patient")
    }
  }, [])

  return (
    <LayoutFourOFour>
      <Seo title="404: Not found" siteType={currentSite} />

      <div className="row md_pt-3">
        <div className="small-12 column">
          <h1>404 Error. Page Not Found.</h1>
        </div>
      </div>

      <div className="row">
        <div className="small-12 column">
          <h2>
            Looking for something? <br />
            You might need to look on a different page.
          </h2>
          <p>Select the button below.</p>
        </div>
      </div>

      <div className="row py-1 lg_pt-1 lg_pb-3">
        <div className="column">
          {isHcp && (
            <Button
              ButtonLink={"/hcp/"}
              ButtonClass={"primary mb-3"}
              ButtonText={"Return Home"}
              ButtonIconAfter={"&nbsp;&gt;"}
            ></Button>
          )}
          {!isHcp && (
            <Button
              ButtonLink={"/"}
              ButtonClass={"primary mb-3"}
              ButtonText={"Return Home"}
              ButtonIconAfter={"&nbsp;&gt;"}
            ></Button>
          )}
        </div>
      </div>
    </LayoutFourOFour>
  )
}

export default NotFoundPage
