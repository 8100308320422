exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-cushings-syndrome-js": () => import("./../../../src/pages/about-cushings-syndrome.js" /* webpackChunkName: "component---src-pages-about-cushings-syndrome-js" */),
  "component---src-pages-hcp-clinical-studies-logics-js": () => import("./../../../src/pages/hcp/clinical-studies/logics.js" /* webpackChunkName: "component---src-pages-hcp-clinical-studies-logics-js" */),
  "component---src-pages-hcp-clinical-studies-sonics-js": () => import("./../../../src/pages/hcp/clinical-studies/sonics.js" /* webpackChunkName: "component---src-pages-hcp-clinical-studies-sonics-js" */),
  "component---src-pages-hcp-dosing-and-monitoring-js": () => import("./../../../src/pages/hcp/dosing-and-monitoring.js" /* webpackChunkName: "component---src-pages-hcp-dosing-and-monitoring-js" */),
  "component---src-pages-hcp-important-safety-information-js": () => import("./../../../src/pages/hcp/important-safety-information.js" /* webpackChunkName: "component---src-pages-hcp-important-safety-information-js" */),
  "component---src-pages-hcp-index-js": () => import("./../../../src/pages/hcp/index.js" /* webpackChunkName: "component---src-pages-hcp-index-js" */),
  "component---src-pages-hcp-moa-js": () => import("./../../../src/pages/hcp/moa.js" /* webpackChunkName: "component---src-pages-hcp-moa-js" */),
  "component---src-pages-hcp-safety-profile-js": () => import("./../../../src/pages/hcp/safety-profile.js" /* webpackChunkName: "component---src-pages-hcp-safety-profile-js" */),
  "component---src-pages-hcp-sitemap-js": () => import("./../../../src/pages/hcp/sitemap.js" /* webpackChunkName: "component---src-pages-hcp-sitemap-js" */),
  "component---src-pages-hcp-support-connect-with-xeris-support-js": () => import("./../../../src/pages/hcp/support/connect-with-xeris-support.js" /* webpackChunkName: "component---src-pages-hcp-support-connect-with-xeris-support-js" */),
  "component---src-pages-hcp-support-tools-and-resources-js": () => import("./../../../src/pages/hcp/support/tools-and-resources.js" /* webpackChunkName: "component---src-pages-hcp-support-tools-and-resources-js" */),
  "component---src-pages-hcp-support-xeris-careconnection-js": () => import("./../../../src/pages/hcp/support/xeris-careconnection.js" /* webpackChunkName: "component---src-pages-hcp-support-xeris-careconnection-js" */),
  "component---src-pages-hcp-unsubscribe-js": () => import("./../../../src/pages/hcp/unsubscribe.js" /* webpackChunkName: "component---src-pages-hcp-unsubscribe-js" */),
  "component---src-pages-important-safety-information-js": () => import("./../../../src/pages/important-safety-information.js" /* webpackChunkName: "component---src-pages-important-safety-information-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-one-on-one-support-meet-your-support-team-js": () => import("./../../../src/pages/one-on-one-support/meet-your-support-team.js" /* webpackChunkName: "component---src-pages-one-on-one-support-meet-your-support-team-js" */),
  "component---src-pages-one-on-one-support-sign-up-for-support-js": () => import("./../../../src/pages/one-on-one-support/sign-up-for-support.js" /* webpackChunkName: "component---src-pages-one-on-one-support-sign-up-for-support-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-taking-recorlev-js": () => import("./../../../src/pages/taking-recorlev.js" /* webpackChunkName: "component---src-pages-taking-recorlev-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-what-is-recorlev-js": () => import("./../../../src/pages/what-is-recorlev.js" /* webpackChunkName: "component---src-pages-what-is-recorlev-js" */)
}

