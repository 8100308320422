import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Section from "../../../components/template-partials/global-components/section"
import InteriorPageHeader from "../../../components/template-partials/global-components/interior-page-header"
import Button from "../../../components/template-partials/global-components/buttons"

const Sonics = () => (
  <Layout>
    <Seo
      title="Clinical Studies - SONICS | RECORLEV® (levoketoconazole)"
      description="See data from the second clinical study of Recorlev safety and efficacy, SONICS."
      ogdescription="See data from the second clinical study of Recorlev safety and efficacy, SONICS. See Important Safety Information, including BOXED WARNING."
      siteType="hcp"
    />
    <InteriorPageHeader alt="SONICS">
      <h1>
        A robust, prospective, multinational clinical trial program included a
        second phase 3 study<sup>1</sup>
      </h1>
    </InteriorPageHeader>
    <Section className="interior-page">
      <div id="study-design" className="row">
        <div className="columns">
          <h2>
            Study design<sup>1</sup>
          </h2>
          <p>
            The SONICS study evaluated the efficacy and safety of Recorlev
            <sup>&reg;</sup> in patients with Cushing’s syndrome in a
            multicenter, open-label study consisting of 3 phases.
          </p>
          <ul>
            <li>mUFC at baseline was 5xULN (SD=269)</li>
            <li>
              SONICS included Recorlev treatment-naïve patients with persistent
              or recurrent disease despite surgery, previously medically treated
              patients, and previously untreated patients
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="columns text-center large-text-left">
          <StaticImage
            className="show-for-large"
            src="../../../images/recorlev-sonics-dosing-guidelines-steps.png"
            alt="A robust, prospective, multinational clinical trial program included a second phase 3 study"
            placeholder="blurred"
            layout="constrained"
            width={1047}
          />
          <StaticImage
            className="hide-for-large"
            src="../../../images/recorlev-sonics-dosing-guidelines-steps-mobile.png"
            alt="A robust, prospective, multinational clinical trial program included a second phase 3 study"
            placeholder="blurred"
            layout="constrained"
            width={1000}
          />
        </div>
      </div>

      <div id="primary-endpoint" className="row md_pt-3 __sonics-endpoint">
        <div className="columns">
          <h2>
            Proven reduction of cortisol in the supportive SONICS study
            <sup>1</sup>
          </h2>
          <h3>Primary endpoint</h3>
          <p>
            <strong>Complete response rate:</strong> The proportion of patients
            with normalization of mUFC at the end of the maintenance phase
            without an increase in dose at any time during 6 months
          </p>
        </div>
      </div>

      <div className="row">
        <div className="columns text-center large-text-left">
          <StaticImage
            className="show-for-large md_mb-2"
            src="../../../images/recorlev-sonics-primary-endpoint.png"
            alt="By the end of dose titration at week 21, 67% of patients achieved mUFC normalization. By the end of the 6-month
            maintenance phase, 31% achieved normalization without a dose increase; 78% achieved normalization or a 50% reduction in mUFC
            regardless of dose increase. By the end of the extended evaluation phase, 17% achieved normalized mUFC without a dose increase."
            placeholder="blurred"
            layout="constrained"
            width={1051}
          />
          <StaticImage
            className="hide-for-large"
            src="../../../images/recorlev-sonics-primary-endpoint.png-mobile.png"
            alt="By the end of dose titration at week 21, 67% of patients achieved mUFC normalization. By the end of the 6-month
            maintenance phase, 31% achieved normalization without a dose increase; 78% achieved normalization or a 50% reduction in mUFC
            regardless of dose increase. By the end of the extended evaluation phase, 17% achieved normalized mUFC without a dose increase."
            placeholder="blurred"
            layout="constrained"
            width={1800}
          />
        </div>
      </div>
      <div className="row">
        <div className="columns">
          <ul className="footnote no-bullets __superscript">
            <li>
              *The study design considered those who received a dose increase
              after titration phase (maintenance phase and beyond) to be
              treatment failures.<sup>2</sup>
            </li>
            <li>
              <sup>†</sup>Data based on 55 maintenance phase completers with
              both baseline and month 6 mUFC data available.<sup>2</sup>
            </li>
          </ul>
          <p className="footnote">
            BID=twice daily; mUFC=mean urinary free cortisol; ULN=upper limit of
            normal.
          </p>
          <p className="footnote">
            <b>References: 1.</b> Recorlev. Prescribing Information. Xeris
            Pharmaceuticals, Inc; 2021. <b>2.</b> Fleseriu M, Pivonello R,
            Elenkova A, et al. Efficacy and safety of levoketoconazole in the
            treatment of endogenous Cushing’s syndrome (SONICS): a phase 3,
            multicentre, open-label, single-arm trial [published correction
            appears in <i>Lancet Diabetes Endocrinol</i>. 2019;7(11):e22].{" "}
            <i>Lancet Diabetes Endocrinol</i>. 2019;7(11):855-865. <b>3.</b>{" "}
            Fleseriu M, Auchus RJ, Greenman&nbsp;Y.&nbsp;Levoketoconazole
            treatment in endogenous Cushing’s syndrome: extended evaluation of
            clinical, biochemical, and radiologic outcomes.{" "}
            <i>Eur J Endocrinol</i>. 2022;187(6):859-871.
          </p>
        </div>
      </div>

      <div className="row py-2 lg_pt-1 lg_pb-3">
        <div className="column text-center">
          <Button
            ButtonLink={"/hcp/dosing-and-monitoring/"}
            ButtonClass={"primary gtm-cta"}
            ButtonText={"Find Out How To Dose And Monitor"}
            ButtonIconAfter={"&nbsp;&gt;"}
            EventLabel={"Find Out How To Dose And Monitor"}
          ></Button>
        </div>
      </div>
    </Section>
  </Layout>
)

export default Sonics
