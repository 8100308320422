import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/template-partials/global-components/section"
import InteriorPageHeader from "../components/template-partials/global-components/interior-page-header"
import Button from "../components/template-partials/global-components/buttons"

const TakingRecorlev = () => (
  <Layout>
    <Seo
      title="Taking Recorlev | Recorlev® (levoketoconazole)"
      description="Learn the optimal dosing and monitoring guidance to follow while taking Recorlev. Please see Important Safety
      Information and full Prescribing Information, including Boxed Warning, on this website."
      ogdescription="Learn the optimal dosing and monitoring guidance to follow while taking Recorlev. Please see Important Safety
      Information and full Prescribing Information, including Boxed Warning, on this website."
    />

    <InteriorPageHeader alt="Taking Recorlev">
      <h1>
        Taking Recorlev<sup>&reg;</sup>
      </h1>
    </InteriorPageHeader>
    <Section className="interior-page">
      <div className="row">
        <div className="small-12 medium-6 column">
          <h2>
            Recorlev is a prescription medicine taken by mouth twice a day with
            or without food
          </h2>

          <figure className="mb-2">
            <StaticImage
              src="../images/dosing-instruction.png"
              alt=""
              placeholder="blurred"
              layout="constrained"
              width={555}
            />
          </figure>

          <h3>Personalized dosing</h3>
          <ul>
            <li>
              Your doctor will personalize your dose to ensure you're taking the
              right amount for you
            </li>
            <li>
              Personalizing means your dose may change more than once while
              you're taking Recorlev<sup>&reg;</sup> (levoketoconazole). This is
              normal. Your doctor is trying to find the right dose of Recorlev
              for you to manage your cortisol levels and side effects
            </li>
          </ul>
        </div>
        <div className="small-12 medium-6 column text-center pt-1 pb-2">
          <StaticImage
            className="show-for-large"
            src="../images/glass-and-pill-illustration.png"
            alt=""
            placeholder="blurred"
            layout="constrained"
            width={325}
          />
          <StaticImage
            className="hide-for-large"
            src="../images/glass-and-pill-illustration-mobile.png"
            alt=""
            placeholder="blurred"
            layout="constrained"
            width={250}
          />
        </div>
      </div>

      <div className="row">
        <div className="small-12 column  mb-2 md_my-2">
          <StaticImage
            src="../images/panther-rx-text-box.png"
            alt="A clinical pharmacist from PANTHERx Rare Pharmacy will check in with you regularly to see how
            you’re feeling and answer any questions you may have"
            placeholder="blurred"
            layout="constrained"
            width={500}
          />
        </div>
      </div>

      <div className="row align-middle">
        <div className="small-12 medium-6 column __monitoring">
          <h2 className="d-none">Side effects</h2>
          {/*<h3>Possible side effects </h3>*/}
          {/*<h4>Side effects can occur with Recorlev, including some that are serious</h4>*/}
          <p>While you’re on Recorlev, your doctor will test you regularly.</p>
          <p>
            Remember to tell your doctor or pharmacist how you’re feeling while
            taking Recorlev so they can find the right dose for you.
          </p>
        </div>
        <div className="small-12 medium-6 column text-center pt-1 pb-3">
          <StaticImage
            src="../images/patient-doctor-illustration.png"
            alt=""
            placeholder="blurred"
            layout="constrained"
            width={523}
          />
        </div>
      </div>

      <div className="row md_my-3 align-center">
        <div className="small-12 medium-9 column">
          <div className="callout-pill tc-primary bg-light mb-2">
            <strong>
              It’s important to share with your doctor what medicines you’re
              taking, including vitamins and over-the-counter medicine.
            </strong>
          </div>
        </div>
      </div>

      <div className="row py-2 lg_pt-1 lg_pb-3">
        <div className="column text-center">
          <Button
            ButtonLink={"/one-on-one-support/meet-your-support-team/"}
            ButtonClass={"primary gtm-cta"}
            ButtonText={"Get To Know Your True Support Partner In Care"}
            ButtonIconAfter={"&nbsp;&gt;"}
            EventLabel={"Get To Know Your True Support Partner In Care"}
          ></Button>
        </div>
      </div>
    </Section>
  </Layout>
)

export default TakingRecorlev
