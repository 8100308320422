import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/template-partials/global-components/section"

const Sitemap = () => (
  <Layout>
    <Seo
      title="Site Map | Recorlev® (levoketoconazole)"
      description="See the site map for the Recorlev Patient Website. Please see Important Safety Information and full Prescribing
      Information, including Boxed Warning, on this website."
      ogdescription="See the site map for the Recorlev Patient Website. Please see Important Safety Information and full Prescribing
      Information, including Boxed Warning, on this website."
    />
    <Section className="interior-page">
      <div className="row pt-2 md_pt-3">
        <div className="small-12 column">
          <h1>Site Map</h1>
        </div>
      </div>
      <div className="row">
        <div className="columns">
          <nav className="sitemap">
            <ul>
              <li>
                <h2>
                  <Link
                    to="/"
                    className="gtm-link-internal"
                    data-gtm-event-label="Home"
                  >
                    Home
                  </Link>
                </h2>
              </li>
              <li>
                <h2>
                  <Link
                    to="/about-cushings-syndrome/"
                    className="gtm-link-internal"
                    data-gtm-event-label="About Cushing's"
                  >
                    About Cushing's
                  </Link>
                </h2>
              </li>
              <li>
                <h2>
                  <Link
                    to="/what-is-recorlev/"
                    className="gtm-link-internal"
                    data-gtm-event-label="What is Recorlev?"
                  >
                    What is Recorlev?
                  </Link>
                </h2>
              </li>
              {/* <li>
                 <h2><Link to="/taking-recorlev/" className="gtm-link-internal" data-gtm-event-label="Taking Recorlev">Taking Recorlev</Link></h2>
              </li>*/}
              <li>
                <h2
                  className="tc-primary gtm-link-internal"
                  data-gtm-event-label="One-on-one support"
                >
                  One-on-one support
                </h2>
                <ul>
                  <li>
                    <h3>
                      <Link
                        to="/one-on-one-support/meet-your-support-team/"
                        className="gtm-link-internal"
                        data-gtm-event-label="Meet your support team"
                      >
                        Meet your support team
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <h3>
                      <Link
                        to="/one-on-one-support/sign-up-for-support/"
                        className="gtm-link-internal"
                        data-gtm-event-label="Sign up for support"
                      >
                        Sign up for support
                      </Link>
                    </h3>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Section>
  </Layout>
)

export default Sitemap
