/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Script, useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Isi from "./template-partials/global-components/isi"
import Footer from "./footer"
import Interstitial from "./template-partials/homepage/interstitial"
import "./layout.scss"
import Cookies from "js-cookie"

import Helmet from "react-helmet"

const Layout = ({ children, location }) => {
  const [showInt, setShowInt] = useState(false)
  const [isHcp, setIsHcp] = useState(false)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            menu {
              name
              link
              eventClass
              eventLabel
              subMenu {
                name
                link
                eventClass
                eventLabel
              }
            }
          }
          headerMenu {
            name
            menu {
              name
              link
              class
              type
              eventClass
              eventLabel
            }
          }
          headerButtons {
            name
            menu {
              name
              link
              class
              type
              eventLabel
            }
          }
          footerLinks {
            name
            link
            eventClass
            eventLabel
          }
          libCode {
            name
            content {
              code
              date
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    let windowLoc = window.location.href
    let loc = location
    let video = document.getElementById("hero-video")
    if (windowLoc.includes("/hcp")) {
      setIsHcp(true)
      if (!Cookies.get("hcpint")) {
        setShowInt(true)
      } else {
        if (video !== null) {
          video.play()
        }
      }
    } else {
      if (Cookies.get("hcpint")) {
        Cookies.remove("hcpint")
      } else {
        return false
      }
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="p3ha895xtbqmdhxm5hi0wvs4tu1jif"
        />
        <meta
          name="google-site-verification"
          content="mDT8dSOvDKw_KdvQfwsHQc7RHHT5loyMlZtgIqUFDzk"
        />
      </Helmet>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        headerMenu={data.site.siteMetadata.headerMenu}
        headerButtons={data.site.siteMetadata.headerButtons}
        menuLinks={data.site.siteMetadata.menuLinks}
      />
      {showInt && <Interstitial visible={true} />}

      {!isHcp && (
        <>
          <Script>
            {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '828711051620002');
            fbq('track', 'PageView');
          `}
          </Script>
          <img
            height="1"
            width="1"
            style={{ display: `none` }}
            src="https://www.facebook.com/tr?id=828711051620002&ev=PageView&noscript=1"
          />
        </>
      )}

      <main>{children}</main>

      <Isi inView="inView" />
      <Footer
        footerLinks={data.site.siteMetadata.footerLinks}
        libCode={data.site.siteMetadata.libCode}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
