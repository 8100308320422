import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Section from "../../../components/template-partials/global-components/section"
import InteriorPageHeader from "../../../components/template-partials/global-components/interior-page-header"

import LogicsDosingGuidelinesDesktop from "../../../images/recorlev-logics-dosing-guidelines-steps.svg"
import LogicsDosingGuidelinesMobile from "../../../images/recorlev-logics-dosing-guidelines-steps-mobile.svg"

import Button from "../../../components/template-partials/global-components/buttons"

const Logics = () => (
  <Layout>
    <Seo
      title="Clinical Studies - LOGICS | RECORLEV® (levoketoconazole)"
      description="See data from the first clinical study of Recorlev safety and efficacy, LOGICS."
      ogdescription="See data from the first clinical study of Recorlev safety and efficacy, LOGICS. See Important Safety Information, including BOXED WARNING."
      siteType="hcp"
    />
    <InteriorPageHeader alt="Logistics">
      <h1>
        LOGICS evaluated the proportion of patients with mUFC normalization
        <sup>1</sup>
      </h1>
    </InteriorPageHeader>
    <Section className="interior-page">
      <div className="row">
        <div className="small-12 column">
          <p>
            LOGICS had an open-label dose titration and maintenance phase for up
            to 19 weeks, followed by an 8-week double-blind, placebo controlled,
            randomized withdrawal phase and 8 weeks{" "}
            <br className="show-for-large" /> of restoration.
            <sup>1,2</sup>
          </p>
          <h2 className="mb-1 md_mb-1">
            Key efficacy endpoint<sup>1</sup>
          </h2>
          <p>
            The proportion of patients with mUFC normalization, defined as a
            patient with mUFC at or below the ULN at the end of randomized
            withdrawal phase without meeting a requirement for early rescue
            during the randomized withdrawal phase.
          </p>
          <div className="row">
            <div className="columns text-center large-text-left">
              <img
                src={LogicsDosingGuidelinesDesktop}
                className="show-for-large md_mb-2"
                alt="LOGICS evaluated the proportion of patients with mUFC normalization Dose titration and maintenance (N=79) Double-blind withdrawl (n=44) Double-blind restoration (n=43)"
              />
              <img
                src={LogicsDosingGuidelinesMobile}
                className="hide-for-large"
                alt="LOGICS evaluated the proportion of patients with mUFC normalization Dose titration and maintenance (N=79) Double-blind withdrawl (n=44) Double-blind restoration (n=43)"
              />
            </div>
            <div className="small-12 columns">
              <ul className="no-bullets footnote __superscript md_px-0">
                <li>
                  *mUFC normalization was defined as patients with mUFC at or
                  below the ULN at the end of the withdrawal phase without
                  requiring early rescue during the randomized withdrawal phase.
                  <sup>2</sup>
                </li>
                <li>
                  <sup>&dagger;</sup>Subjects eligible for the
                  randomized-withdrawal phase achieved stable therapeutic dose
                  (defined as the dose providing mUFC at or below the ULN,
                  determined from 3 adequate 24-h urine collections) and
                  maintained the dose associated with mUFC normalization for at
                  least the final 4 weeks of the titration maintenance phase.
                  <sup>2</sup>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="small-12 column">
          <h2>
            At the end of the randomized withdrawal phase, significantly more
            patients taking Recorlev achieved mUFC normalization
            <sup>&Dagger;</sup> vs placebo<sup>1</sup>
          </h2>
        </div>
        <div className="small-12 large-7 column">
          <StaticImage
            className="show-for-medium"
            src="../../../images/recorlev-mUFC-normalization-chart.png"
            alt="52% of participants (n=21) taking Recorlev achieved mUFC normalization compared with placebo 6% (n=18)"
            placeholder="blurred"
            layout="constrained"
            width={647}
          />
          <StaticImage
            className="hide-for-medium"
            src="../../../images/recorlev-mUFC-normalization-chart.png"
            alt="52% of participants (n=21) taking Recorlev achieved mUFC normalization compared with placebo 6% (n=18)"
            placeholder="blurred"
            layout="constrained"
            width={500}
          />
        </div>

        <div className="small-12 column mt-3 md_pt-3 lg_pt-2">
          <ul className="no-bullets footnote __superscript md_px-0">
            <li>
              <sup>&Dagger;</sup>Among the 39 patients who entered the
              randomized withdrawal phase and had normal mUFC.
            </li>
          </ul>
          <p className="footnote">
            BID=twice daily; mUFC=mean urinary free cortisol; ULN=upper limit of
            normal.
          </p>
          <p className="footnote">
            <b>References: 1.</b> Recorlev [prescribing information]. Chicago,
            IL: Xeris Pharmaceuticals, Inc. <b>2.</b> Pivonello R, Zacharieva S,
            Elenkova A, et al. Levoketoconazole in the treatment of patients
            with endogenous Cushing’s syndrome: a double-blind,
            placebo-controlled, randomized withdrawal study (LOGICS).{" "}
            <i>Pituitary.</i> 2022;25(6):911-926. doi:10.1007/s11102-022-01263-7
          </p>
        </div>
      </div>

      <div className="row py-2 lg_py-3">
        <div className="column text-center">
          <Button
            ButtonLink={"/hcp/clinical-studies/sonics/"}
            ButtonClass={"primary gtm-cta"}
            ButtonText={"Sonics Study Results"}
            ButtonIconAfter={"&nbsp;&gt;"}
            EventLabel={"Sonics Study Results"}
          ></Button>
        </div>
      </div>
    </Section>
  </Layout>
)

export default Logics
