import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { globalHistory as history } from "@reach/router"

import { isHCP } from "../../../helpers/helpers"

const Navigation = ({ menuLinks }) => {
  let { location } = history
  //console.log( location.pathname ) // **will change with every location update**
  let currentLocation = location.pathname

  const [currentPage, setCurrentPage] = useState("")
  const [currentParent, setCurrentParent] = useState("")
  const [currentSite, setCurrentSite] = useState("")

  let slashCount = currentLocation.split("/").length - 1

  let siteLinks

  useEffect(() => {
    if (isHCP()) {
      setCurrentSite("hcp")
    } else {
      setCurrentSite("patient")
    }

    if (currentSite === "hcp") {
      //If Sub Page
      if (slashCount === 3) {
        setCurrentParent(currentLocation.split("/")[2])
        setCurrentPage(currentLocation.split("/")[3])

        //If Single Page
      } else if (slashCount === 2) {
        setCurrentParent(currentLocation.split("/")[1])
        setCurrentPage(currentLocation.split("/")[2])
      } else if (slashCount === 1) {
        setCurrentPage(currentPage)
      }
    } else if (currentSite === "patient") {
      //If Sub Page
      if (slashCount === 2) {
        setCurrentParent(currentLocation.split("/")[1])
        setCurrentPage(currentLocation.split("/")[2])
      } else if (slashCount === 1) {
        setCurrentPage(currentLocation.split("/")[1])
      }
    }
  }, [currentSite, currentPage, currentParent, slashCount, currentLocation])

  const toggleMobileDrawer = e => {
    let width = document.body.clientWidth
    if (width <= 1024) {
      e.currentTarget.parentElement.classList.toggle("isOpen")
      e.preventDefault()
    } else {
      return true
    }
  }

  if (currentSite === "hcp") {
    siteLinks = menuLinks[0].menu
  } else {
    siteLinks = menuLinks[1].menu
  }

  const returnSubItems = (e, i) => {
    let name = ""
    if (e.name === "Xeris CareConnection") {
      name = "Xeris CareConnection&trade;"
    } else {
      name = e.name
    }
    return (
      <li tabIndex={i} key={e.name}>
        <Link
          data-gtm-event-label={e.eventLabel}
          className={e.eventClass}
          to={e.link}
          dangerouslySetInnerHTML={{ __html: name }}
        ></Link>
      </li>
    )
  }

  const checkCurrentPage = type => {
    let pageName = type
    pageName = pageName
      .toLowerCase()
      .replace(/\s/g, "-")
      .replace("and", "")
      .replace("--", "-")
      .replace("'", "")
      .replace("?", "")

    if (pageName === currentPage) {
      return "active-parent "
    } else if (pageName === "safety") {
      return "active-parent "
    }
  }

  const returnItem = (e, i) => {
    let theClass = ""
    //Check item for SubMenu
    if (e.subMenu != null) {
      return (
        <li
          role="menuitem"
          aria-haspopup="true"
          key={e.name}
          className={(() => {
            e.subMenu.map((subLink, i) => {
              //Get current Submanu Item name
              let pageName = subLink.name
              pageName = pageName.toLowerCase()
              pageName = pageName.replace(/\s/g, "-")

              if (currentPage === pageName) {
                theClass = "active-parent isOpen "
              }
              return null
            })
            theClass += "has-dropdown"
            return theClass
          })()}
        >
          <a
            href="#"
            aria-haspopup="true"
            dangerouslySetInnerHTML={{ __html: e.name }}
            onClick={e => toggleMobileDrawer(e)}
          ></a>
          <ul className={"dropdown"} aria-label="submenu" role="menu">
            {e.subMenu.map((subLink, i) => {
              return returnSubItems(subLink, i)
            })}
          </ul>
        </li>
      )
    } else {
      if (e.name === "Home") {
        theClass = "hide-for-large"
      }
      let name
      if (e.name === "Safety Profile") {
        name = "Safety"
      } else {
        name = e.name
      }
      return (
        <li key={name} className={checkCurrentPage(e.name)}>
          <Link
            to={e.link}
            data-gtm-event-label={e.eventLabel}
            dangerouslySetInnerHTML={{ __html: name }}
            aria-haspopup={e.subMenu && e.subMenu.length > 0 ? "true" : "false"}
            className={theClass + " " + e.eventClass}
            // rel={e.linkRel}
            // hrefLang={e.hrefLang}
          ></Link>
        </li>
      )
    }
  }

  const menuItems = siteLinks.map((item, i) => returnItem(item, i))

  return (
    <nav id="mainNavigation" aria-label="Main menu">
      <ul className="w-100 d-flex">{menuItems}</ul>
    </nav>
  )
}

export default Navigation
