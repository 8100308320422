import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

const InteriorPageHeader = props => {
  const [takingRecorlev, setIsTakingRecorlev] = useState(false)
  const [supportTeam, setIsSupportTeam] = useState(false)

  useEffect(() => {
    let windowLoc = window.location.href
    if (windowLoc.includes("/taking-recorlev")) {
      setIsTakingRecorlev(true)
    } else if (windowLoc.includes("/meet-your-support-team")) {
      setIsSupportTeam(true)
    }
  }, [])

  return (
    <section id={props.id} className="interior-page__header ">
      <div className="row row--inner small-collapse large-uncollapse">
        <div className="column small-12">
          <div className="combined-hero row small-uncollapse large-collapse column">
            <div
              id="graphic-container"
              className="column small-12 large-3 align-self-bottom show-for-large"
            >
              <div className="d-flex align-self-bottom">
                {takingRecorlev && (
                  <StaticImage
                    className=""
                    src="../../../images/patient-taking.png"
                    alt=""
                    placeholder="blurred"
                    layout="constrained"
                    width={321}
                  />
                )}
                {supportTeam && (
                  <StaticImage
                    className=""
                    src="../../../images/patient-laptop-banner.png"
                    alt=""
                    placeholder="blurred"
                    layout="constrained"
                    width={288}
                  />
                )}
                {!takingRecorlev && !supportTeam && (
                  <StaticImage
                    className=""
                    src="../../../images/patient-portrait-banner.png"
                    alt=""
                    placeholder="blurred"
                    layout="constrained"
                    width={248}
                  />
                )}
              </div>
            </div>
            <div id="text" className="column small-12 large-9">
              <div className="row column align-self-middle h-100">
                <div className="row align-middle h-100">
                  <div className="column small-12">{props.children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

InteriorPageHeader.defaultProps = {
  alt: "This is where you have alt text",
  roundImage: "https://placehold.it/650x650?text=FPO+ADD+URL+PROP!",
}

InteriorPageHeader.propTypes = {
  children: PropTypes.node.isRequired,
  //roundImage: PropTypes.node.isRequired,
  //backgroundImage: PropTypes.node.isRequired,
  alt: PropTypes.string,
}

export default InteriorPageHeader
