import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import CheckBrowser from "./Checkbrowser"

const HeroPageHeader = props => {
  const [isHcp, setIsHcp] = useState(false)

  useEffect(() => {
    let windowLoc = window.location.href
    if (windowLoc.includes("/hcp/")) {
      setIsHcp(true)
    } else {
      setIsHcp(false)
    }
  }, [])

  return (
    <section id={props.id} className="hero-page__header">
      <div className="row row--inner small-collapse large-uncollapse">
        <div className="column small-12">
          <div
            style={{
              display: "flex",
              backgroundColor: `#ffdd82`,
              overflow: `hidden`,
            }}
            className="row small-uncollapse large-collapse column align-bottom"
          >
            <div className="medium-5 column text-center small-order-2 medium-order-1 large-order-1 __hp-flowers">
              <CheckBrowser />
            </div>

            <div
              id="text"
              className="small-12 medium-7 column small-order-1 medium-order-1 large-order-2 __hp-text align-self-middle"
            >
              <div className="row column align-self-middle h-100">
                <div className="row align-middle h-100">
                  <div className="column small-12">
                    {isHcp && (
                      <StaticImage
                        className=""
                        src="../../../images/hcp-hero-text.png"
                        alt="Cortisol Lowering is Only the Beginning of Her Story"
                        placeholder="blurred"
                        layout="constrained"
                        width={709}
                      />
                    )}
                    {!isHcp && (
                      <StaticImage
                        className=""
                        src="../../../images/cortisol-lowering-banner.png"
                        alt="Cortisol lowering is only the beginning of her story: Cushing’s turned my life upside down, but now I
                      have what I need to take control"
                        placeholder="blurred"
                        layout="constrained"
                        width={709}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

HeroPageHeader.defaultProps = {
  alt: "This is where you have alt text",
  roundImage: "https://placehold.it/650x650?text=FPO+ADD+URL+PROP!",
}

HeroPageHeader.propTypes = {
  //children: PropTypes.node.isRequired,
  //roundImage: PropTypes.node.isRequired,
  backgroundImage: PropTypes.node.isRequired,
  alt: PropTypes.string,
}

export default HeroPageHeader
