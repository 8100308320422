import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/template-partials/global-components/section"

import { patientDetails } from "../components/template-partials/isi/patient-isi"

const ImportantSafetyInformationPatient = () => {
  let isiText = patientDetails.patientIsiFull

  return (
    <Layout>
      <Seo title="Important Safety Information" />
      <Section className="interior-page pb-0 md_pb-0">
        <div className="row pt-2">
          <div className="small-12 columns">
            <h1>
              INDICATION AND IMPORTANT SAFETY INFORMATION FOR
              <br /> Recorlev<sup>&reg;</sup> (levoketoconazole) tablets
            </h1>
          </div>
        </div>
        <div className="row">
          <div
            className="small-12 columns"
            dangerouslySetInnerHTML={{ __html: isiText }}
          ></div>
        </div>
      </Section>
    </Layout>
  )
}

export default ImportantSafetyInformationPatient
