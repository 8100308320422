import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"

import Button from "../../components/template-partials/global-components/buttons"

const Safety = () => (
  <Layout>
    <Seo
      title="Safety Profile | RECORLEV® (levoketoconazole)"
      description="Learn about Recorlev safety profile including adverse reactions and their prevalence in the studies, toleration, and treatment discontinuation."
      ogdescription="Learn about Recorlev safety profile including adverse reactions and their prevalence in the studies, toleration, and treatment discontinuation. See Important Safety Information, including BOXED WARNING."
      siteType="hcp"
    />
    <InteriorPageHeader alt="Safety">
      <h1>A proven safety and tolerability profile</h1>
    </InteriorPageHeader>
    <Section className="interior-page">
      <div className="row">
        <div className="small-12 column">
          <h2>
            Recorlev<sup>&reg;</sup> was studied in patients for more than 3
            years<sup>1,2</sup>
          </h2>

          <ul>
            <li>Up to 27 weeks in LOGICS</li>
            <li>Up to 73 weeks in SONICS</li>
            <li>More than 155 weeks in OPTICS</li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="small-12 column">
          <h4>
            Adverse reactions occurring in &ge;20% of Cushing's syndrome
            patients treated with Recorlev in LOGICS and SONICS<sup>1</sup>
          </h4>
        </div>

        <div className="small-12 medium-6 column __safety-tables">
          <strong className="h5 fw-bold tc-primary md_pb-0 md_mb-0 d-block">
            LOGICS
          </strong>
          <StaticImage
            src="../../images/recorlev-adverse-reactions-table-logics.png"
            className="show-for-large md_mb-1"
            alt="LOGICS adverse reaction type Nausea/Vomiting, Hypokalemia, Systemic hypertension, Hemorrhage/Contusion,
            Headache, Abnormal uterine bleeding"
            placeholder="blurred"
            layout="constrained"
            width={609}
          />

          <StaticImage
            src="../../images/recorlev-adverse-reactions-table-logics-mobile.png"
            className="hide-for-large mb-2"
            alt="LOGICS adverse reaction type Nausea/Vomiting, Hypokalemia, Systemic hypertension, Hemorrhage/Contusion, Headache, Abnormal uterine bleeding"
            placeholder="blurred"
            layout="constrained"
            width={600}
          />

          <ul className="no-bullets footnote __superscript">
            <li>
              <sup>a</sup>Hemorrhage/contusion includes blood urine present,
              epistaxis, eye hemorrhage, gingival bleeding, hematoma, hematuria,
              hemorrhoidal hemorrhage, melena, and scleral hemorrhage.
            </li>
          </ul>
        </div>

        <div className="small-12 medium-6 column __safety-tables">
          <strong className="h5 fw-bold tc-primary md_pb-0 md_mb-0 d-block">
            SONICS
          </strong>

          <StaticImage
            src="../../images/recorlev-adverse-reactions-table-sonics.png"
            className="show-for-large lg_mb-0"
            alt="SONICS adverse reaction type Erythema, Hemorrhage/Contusion, Fatigue, Headache, Nausea/Vomiting, Abdominal pain/dyspepsia, Arthritis, Upper respiratory infection, Myalgia, Abnormal uterine bleeding, Arrhythmia, Back pain, Insomnia/Sleep disturbances, Peripheral edema, Systemic hypertension"
            placeholder="blurred"
            layout="constrained"
            width={1905}
          />

          <StaticImage
            src="../../images/recorlev-adverse-reactions-table-sonics-mobile.png"
            className="hide-for-large mb-2"
            alt="SONICS adverse reaction type Erythema, Hemorrhage/Contusion, Fatigue, Headache, Nausea/Vomiting, Abdominal pain/dyspepsia, Arthritis, Upper respiratory infection, Myalgia, Abnormal uterine bleeding, Arrhythmia, Back pain, Insomnia/Sleep disturbances, Peripheral edema, Systemic hypertension"
            placeholder="blurred"
            layout="constrained"
            width={600}
          />

          <ul className="no-bullets footnote __superscript">
            <li>
              <sup>a</sup>Erythema includes flushing.
            </li>
            <li>
              <sup>b</sup>Hemorrhage/Contusion includes blood urine present,
              conjunctival hemorrhage, ecchymosis, epistaxis, hematoma,
              hyphemia, and red blood cells urine.
            </li>
            <li>
              <sup>c</sup>Abdominal pain/dyspepsia includes abdominal
              discomfort, abdominal distension, dyspepsia, gastritis, and other
              related terms.
            </li>
            <li>
              <sup>d</sup>Arrhythmia includes bradycardia, carotid pulse
              increased, defect conduction intraventricular, electrocardiogram
              QT prolonged, electrocardiogram T wave abnormal, heart rate
              increased, palpitations, and sinus bradycardia.
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="small-12 column">
          <p className="">
            Pooled safety data for hepatic injury and other liver-related
            adverse reactions and elevations in AST or ALT: at least one
            liver-related adverse reaction (27%), liver enzyme elevation* (20%),
            and AST or ALT &gt; ULN<sup>†</sup> (45%).
          </p>

          <ul className="no-bullets footnote __superscript">
            <li>
              *Liver enzyme elevation refers to elevation in AST, ALT, alkaline
              phosphatase, or gamma-glutamyl transferase.
            </li>
            <li>
              <sup>†</sup>Not all elevations in liver enzymes were reported as
              adverse reactions during the studies.
            </li>
          </ul>

          <h2>Treatment discontinuation</h2>

          <ul>
            <li>
              In the LOGICS study,<sup>‡</sup> during the titration maintenance
              phase, 15/79 patients (19%) discontinued Recorlev due to a TEAE;
              the most common TEAE leading to discontinuation was nausea in 3/79
              patients (4%)<sup>3</sup>
            </li>
            <li>
              There were no patients who discontinued Recorlev during the
              randomized withdrawal phase of the LOGICS study<sup>3‡</sup>
            </li>
            <li>
              In the SONICS study, 12/94 patients (13%) discontinued Recorlev
              due to an adverse reaction; the most common adverse reactions
              leading to discontinuation were liver-related (6/94)<sup>4</sup>
            </li>
            <li>
              No patients discontinued either phase 3 study due to adrenal
              insufficiency<sup>3,4</sup>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="column">
          <ul className="no-bullets footnote __superscript">
            <li>
              <sup>‡</sup>Safety data for the LOGICS study include results
              recorded through the randomized withdrawal phase.<sup>1</sup>
            </li>
          </ul>

          <p className="footnote">
            ALT=alanine aminotransferase; AST=aspartate aminotransferase;
            TEAE=treatment-emergent adverse event; ULN=upper limit of normal.
          </p>
          <p className="footnote">
            <b>References: 1.</b> Recorlev. Prescribing Information. Xeris
            Pharmaceuticals, Inc; 2021. <b>2.</b> US National Library of
            Medicine. Open-label treatment in Cushing’s syndrome (OPTICS).
            Accessed December 23, 2021.{" "}
            <a
              href="https://clinicaltrials.gov/ct2/show/NCT03621280"
              style={{ color: "#000000" }}
            >
              https://clinicaltrials.gov/ct2/show/NCT03621280.
            </a>{" "}
            <b>3.</b> Data on file. Xeris Pharmaceuticals, Inc. 2021. <b>4.</b>{" "}
            Fleseriu M, Pivonello R, Elenkova A, et al. Efficacy and safety of
            levoketoconazole in the treatment of endogenous Cushing’s syndrome
            (SONICS): a phase 3, multicentre, open-label, single-arm trial
            [published correction appears in <i>Lancet Diabetes Endocrinol</i>.
            2019;7(11):e22]. <i>Lancet Diabetes Endocrinol</i>.
            2019;7(11):855-865.
          </p>
        </div>
      </div>

      <div className="row py-2 lg_pt-1 lg_pb-3">
        <div className="column text-center">
          <Button
            ButtonLink={"/hcp/moa/"}
            ButtonClass={"primary gtm-cta"}
            ButtonText={"How does Recorlev work? Find out"}
            ButtonIconAfter={"&nbsp;&gt;"}
            EventLabel={"How does Recorlev work? Find out"}
          ></Button>
        </div>
      </div>
    </Section>
  </Layout>
)

export default Safety
