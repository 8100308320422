/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, ogdescription, lang, meta, title, siteType }) {
  let schemaPatient = {
    "@context": "https://schema.org/",
    "@type": "MedicalWebPage",
    url: "https://www.recorlev.com/",
    audience: "https://health-lifesci.schema.org/Patient",
    creator: {
      "@type": "Organization",
      name: "Xeris Pharmaceuticals, Inc.",
      url: "https://www.xerispharma.com/",
      logo: "https://www.xerispharma.com/images/xeris-logo.svg",
    },
    specialty: "https://health-lifesci.schema.org/Endocrine",
    about: {
      "@type": "MedicalIndication",
      name: "Cushingís syndrome",
      sameAs: "",
      relevantSpecialty: "https://health-lifesci.schema.org/Endocrine",
    },
    mainEntity: {
      "@type": "Drug",
      name: [
        "Recorlev",
        {
          "@type": "PronounceableText",
          inLanguage: "en-US",
          textValue: "Recorlev",
          speechToTextMarkup: "IPA",
          phoneticText: "RIH-CORE-UH-LEV",
        },
      ],
      image:
        "https://www.recorlev.com/static/76539488726fe7c7222edc9dcc6dc542/6d012/recorlev-logo.webp",
      activeIngredient: "levoketoconazole",
      nonProprietaryName: "levoketoconazole",
      legalStatus: "FDA Approved",
      isProprietary: "True",
      relevantSpecialty: "https://health-lifesci.schema.org/Endocrine",
      manufacturer: {
        "@type": "Organization",
        name: "Xeris Pharmaceuticals, Inc.",
        url: "https://www.xerispharma.com/",
        logo: "https://www.xerispharma.com/images/xeris-logo.svg",
      },
      description:
        "RecorlevÆ (levoketoconazole) is a prescription medicine used to treat high cortisol (endogenous hypercortisolemia) levels in adult patients with Cushingís syndrome who cannot have surgery or who have had surgery that did not cure their Cushing's syndrome.",
      dosageForm: "Oral tablets",
      drugUnit: "150-mg tablet",
      doseSchedule: {
        "@type": "DoseSchedule",
        doseUnit: "mg",
        doseValue: "150",
        frequency: "Twice daily",
        targetPopulation:
          "Adult patients with high cortisol levels from Cushing's syndrome who cannot have surgery or who had surgery that did not cure their Cushing's syndrome.",
      },
      labelDetails: "https://www.recorlev.com/full-prescribing-information.pdf",
      administrationRoute: "Oral",
      prescriptionStatus: "https://health-lifesci.schema.org/PrescriptionOnly",
      prescribingInfo:
        "https://www.recorlev.com/full-prescribing-information.pdf",
      foodWarning: "",
      alcoholWarning: "",
      breastfeedingWarning: "",
      warning:
        "WARNING: HEPATOTOXICITY AND QT PROLONGATION. See full prescribing information for complete boxed warning. ï Cases of hepatotoxicity with fatal outcome or requiring liver transplantation have been reported with oral ketoconazole.Some patients had no obvious risk factors for liver disease.RECORLEV is associated with serious hepatotoxicity.Evaluate liver enzymes prior to and during treatment(5.1)ï RECORLEV is associated with dose - related QT interval prolongation.QT interval prolongation may result in life threatening ventricular dysrhythmias such as torsades de pointes.Perform ECG prior to and during treatment(5.2)",
    },
  }
  let schemaHCP = {
    "@context": "https://schema.org/",
    "@type": "MedicalWebPage",
    url: "https://www.recorlev.com/hcp",
    audience: "https://health-lifesci.schema.org/Clinician",
    creator: {
      "@type": "Organization",
      name: "Xeris Biopharma Holdings",
      url: "https://www.xerispharma.com/",
      logo: "https://www.xerispharma.com/images/xeris-logo.svg",
    },
    specialty: "https://health-lifesci.schema.org/Endocrine",
    about: {
      "@type": "MedicalIndication",
      name: "Cushingís syndrome",
      sameAs: "",
      relevantSpecialty: "https://health-lifesci.schema.org/Endocrine",
    },
    mainEntity: {
      "@type": "Drug",
      name: [
        "Recorlev",
        {
          "@type": "PronounceableText",
          inLanguage: "en-US",
          textValue: "RECORLEV",
          speechToTextMarkup: "IPA",
          phoneticText: "[ri-core-uh-lev]",
        },
      ],
      image:
        "https://www.recorlev.com/static/76539488726fe7c7222edc9dcc6dc542/6d012/recorlev-logo.webp",
      activeIngredient: "levoketoconazole",
      nonProprietaryName: "levoketoconazole",
      legalStatus: "FDA-Approved",
      isProprietary: "True",
      relevantSpecialty: "https://health-lifesci.schema.org/Endocrine",
      manufacturer: {
        "@type": "Organization",
        name: "Xeris Biopharma Holdings",
        url: "https://www.xerispharma.com/",
        logo: "https://www.xerispharma.com/images/xeris-logo.svg",
      },
      description:
        "RECORLEV is a cortisol synthesis inhibitor used to treat endogenous hypercortisolemia in adult patients with Cushingís syndrome",
      dosageForm: "Tablet",
      drugUnit: "150 mg tablet",
      doseSchedule: {
        "@type": "DoseSchedule",
        doseUnit: "mg",
        doseValue: "150",
        frequency: "Twice daily",
        targetPopulation: "Adults",
      },
      labelDetails: "https://www.recorlev.com/full-prescribing-information.pdf",
      administrationRoute: "Oral",
      prescriptionStatus: "https://health-lifesci.schema.org/PrescriptionOnly",
      prescribingInfo:
        "https://www.recorlev.com/full-prescribing-information.pdf",
      foodWarning: "",
      alcoholWarning: "",
      breastfeedingWarning: "",
      mechanismOfAction:
        "In vitro, levoketoconazole inhibits key steps in the synthesis of cortisol and testosterone, principally those mediated by CYP11B1, CYP11A1, and CYP17A1",
      warning:
        "Cases of hepatotoxicity with fatal outcome or requiring liver transplantation have been reported with oral ketoconazole. Some patients had no obvious risk factors for liver disease. RECORLEV is associated with serious hepatoxicity. Evaluate liver enzymes prior to and during treatment. RECORLEV is associated with dose-related QT interval prolongation. QT interval prolongation may result in life-threatening ventricular dysrhythmias such as torsades de pointes. Perform ECG prior to and during treatment",
    },
  }

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const ogDescription = ogdescription || site.siteMetadata.description

  const defaultTitle = site.siteMetadata?.title
  const defaultSiteType = siteType

  let slug = title
  slug = slug.toLowerCase()
  slug = slug.replace(/\s/g, "-")

  const [isWindow, setIsWindow] = useState(false)
  const [windowURL, setWindowURL] = useState("")
  const [ogImage, setogImage] = useState(
    "https://recorlev.com/recorlev-logo.png"
  )

  useEffect(() => {
    let windowLoc = window.location.href
    // windowLoc = 'https://recorlev.com/' + windowLoc;
    typeof window !== "undefined" && setIsWindow(true)
    typeof window !== "undefined" && setWindowURL(windowLoc)
    if (windowLoc.includes("/about-cushings-syndrome")) {
      setogImage("https://recorlev.com/recorlev-non-brand-og-image.jpg")
    }
  }, [ogImage])

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: defaultSiteType + " " + slug,
      }}
      title={title}
      // titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: ogDescription,
        },
      ].concat(meta)}
    >
      {isWindow && <link rel="alternate" hrefLang="en-us" href={windowURL} />}
      {isWindow && siteType === "patient" && (
        <script type="application/ld+json">
          {JSON.stringify(schemaPatient)}
        </script>
      )}
      {isWindow && siteType !== "patient" && (
        <script type="application/ld+json">{JSON.stringify(schemaHCP)}</script>
      )}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  siteType: `patient`,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  ogdescription: PropTypes.string,
  siteType: PropTypes.string,
}

export default Seo
