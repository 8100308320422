import React from "react"
import { navigate } from "gatsby"
import Cookies from "js-cookie"

const Interstitial = props => {
  const ok = e => {
    let int = document.getElementById("int-container")
    let video = document.getElementById("hero-video")
    int.classList.remove("isVisible")
    Cookies.set("hcpint", true)
    if (video !== null) {
      video.play()
    }
    e.preventDefault()
  }

  const cancel = e => {
    Cookies.remove("hcpint")
    navigate(`/`)
    e.preventDefault()
  }

  return (
    <div
      id="int-container"
      className={
        props.visible
          ? `interstitial-container isVisible`
          : `interstitial-container`
      }
    >
      <div className="row align-middle" style={{ height: "100%" }}>
        <div className="columns small-12 medium-offset-2 medium-8 large-offset-3 large-7  text-center">
          <div className="modal">
            <div className="row align-middle">
              <div className="columns small-12 text-left">
                <h5 className="tc-white md_mb-0">
                  Healthcare Professionals Site
                </h5>
                <p>
                  The information contained in this section of the site is
                  intended for US healthcare professionals only. Click "OK" if
                  you are a healthcare professional.
                </p>
                <div className="row align-center">
                  <div className="columns small-6  xlarge-4">
                    <button
                      aria-label="button"
                      id="modal-ok"
                      className="gtm-link-internal"
                      data-gtm-event-label="https://recorlev-hcp-caregiver-staging.azurewebsites.net/hcp/"
                      onClick={e => ok(e)}
                    >
                      OK
                    </button>
                  </div>
                  <div className="columns small-6 xlarge-4">
                    <button
                      aria-label="button"
                      id="modal-cancel"
                      className="gtm-link-external"
                      data-gtm-event-label="https://www.recorlev.com/"
                      onClick={e => cancel(e)}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Interstitial
