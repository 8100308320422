import React, { useEffect } from "react"

import heroVideo from "../../../images/hero.mp4#t=0,300"
import heroPoster from "../../../images/hcp-hero-no-flowers.png"
import { isHCP } from "../../../helpers/helpers"

const Video = props => {
  const handleClick = e => {
    let video = document.getElementById(e.target.id)
    video.play()
  }

  useEffect(() => {
    if (!isHCP()) {
      document.getElementById("hero-video").play()
    }
  }, [])

  return (
    <video
      style={{ top: `0.5rem`, left: `0.5rem`, position: `relative` }}
      id="hero-video"
      poster={heroPoster}
      alt="Cortisol lowering is only the begining of her story"
      muted
      width="100%"
      onClick={e => handleClick(e)}
    >
      <source src={heroVideo} type="video/mp4" />
    </video>
  )
}
export default Video
