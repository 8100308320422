import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Section from "../../../components/template-partials/global-components/section"
import InteriorPageHeader from "../../../components/template-partials/global-components/interior-page-header"
import Button from "../../../components/template-partials/global-components/buttons"
import IconList from "../../../components/template-partials/global-components/icon-list"

const RequestARepresentative = () => (
  <Layout>
    <Seo
      title="Xeris CareConnection™ | RECORLEV® (levoketoconazole)"
      description="Learn about the Xeris support program for your patients, Xeris CareConnection."
      ogdescription="Learn about the Xeris support program for your patients, Xeris CareConnection. See Important Safety Information, including BOXED WARNING."
      siteType="hcp"
    />
    <InteriorPageHeader alt="Connect with Xeris support">
      <h1>Unparalleled support throughout the treatment journey</h1>
    </InteriorPageHeader>
    <Section className="interior-page">
      <div className="row">
        <div className="columns text-center medium-text-left">
          <StaticImage
            className="show-for-medium"
            src="../../../images/xeris-care-connection-logo.png"
            alt="Xeris Care Connection"
            placeholder="blurred"
            layout="constrained"
            width={361}
          />
        </div>
      </div>

      <div className="row">
        <div className="column">
          <h2>
            Xeris CareConnection<sup className="h2-trade">&trade;</sup> partners
            with your office to provide your patients with one-on-one support
          </h2>
        </div>
      </div>

      <div className="row">
        <div className="small-12 medium-6 large-8 column">
          <IconList
            IconImgSrc={
              <StaticImage
                src="../../../images/icons/pharmacy-icon.png"
                className="mb-1"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={102}
              />
            }
            ListTitle={
              <>
                <h3>PANTHERx Rare Pharmacy</h3>
              </>
            }
            ListTextArea={
              <>
                <ul>
                  <li>
                    The first national pharmacy to hold an accredited
                    distinction in rare disease is the only pharmacy that
                    carries Recorlev<sup>&reg;</sup>
                  </li>
                  <li>Delivers Recorlev directly to patients</li>
                  <li>
                    Works with your office to get information needed for
                    patients' insurance coverage
                  </li>
                  <li>
                    Will call patients regularly to check in and have a clinical
                    pharmacist who specializes in endocrinology products speak
                    with them about how they're doing on Recorlev
                  </li>
                  <li>Will help with managing drug-drug interactions</li>
                </ul>
              </>
            }
          ></IconList>

          <IconList
            IconImgSrc={
              <StaticImage
                src="../../../images/icons/hand-shaking-icon.png"
                className="mb-1"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={121}
              />
            }
            ListTitle={
              <>
                <h3>Dedicated Patient Access Managers (PAMs)</h3>
              </>
            }
            ListTextArea={
              <>
                <ul>
                  <li>
                    Help your staff track patient initiation and relay
                    information to your office
                  </li>
                </ul>
              </>
            }
          ></IconList>
        </div>

        <div className="small-12 medium-6 large-4 column md_pt-2 _contact-shape --support show-for-medium">
          <div className="p-relative text-center">
            <StaticImage
              src="../../../images/contact-shape.png"
              alt="contact-shape"
              placeholder="blurred"
              layout="constrained"
              width={618}
            />
            <div className="contact-shape-text p-absolute">
              <p>
                <strong>
                  Call{" "}
                  <a
                    href="tel:18444447258"
                    className="gtm-link-internal"
                    data-gtm-event-label="1-844-444-RCLV (7258)"
                  >
                    1-844-444-RCLV (7258)
                  </a>{" "}
                  <span>for support</span>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row  md_py-3 lg_py-3">
        <div className="small-12 medium-7 column">
          <h4>Support for patients and caregivers</h4>

          <IconList
            IconImgSrc={
              <StaticImage
                src="../../../images/icons/zero-dollar-icon.png"
                className="mb-1"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={77}
              />
            }
            ListTitle={
              <>
                <h4 className="tc-primary">Financial assistance</h4>
              </>
            }
            ListTextArea={
              <>
                <ul>
                  <li>
                    <b>$0 co-pay program</b> for eligible commercial patients
                  </li>
                  <li>
                    Access and support for patients with and without insurance
                  </li>
                </ul>
              </>
            }
          ></IconList>

          <IconList
            IconImgSrc={
              <StaticImage
                src="../../../images/icons/prescription-icon.png"
                className="mb-1"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={70}
              />
            }
            ListTitle={
              <>
                <h3>Filling prescriptions</h3>
              </>
            }
            ListTextArea={
              <>
                <ul>
                  <li>
                    Convenient, free specialty pharmacy services, including 24/7
                    support and home delivery of Recorlev
                  </li>
                </ul>
              </>
            }
          ></IconList>

          <IconList
            IconImgSrc={
              <StaticImage
                src="../../../images/icons/education-icon.png"
                className="mb-1"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={84}
              />
            }
            ListTitle={
              <>
                <h3>Education</h3>
              </>
            }
            ListTextArea={
              <>
                <ul>
                  <li>
                    Ongoing education about Cushing's syndrome and treatment
                    with Recorlev
                  </li>
                </ul>
              </>
            }
          ></IconList>

          <IconList
            IconImgSrc={
              <StaticImage
                src="../../../images/icons/ongoing-support-icon.png"
                className="mb-1"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={84}
              />
            }
            ListTitle={
              <>
                <h3>Specialized ongoing support</h3>
              </>
            }
            ListTextArea={
              <>
                <ul>
                  <li>
                    Clinical pharmacists who specialize in endocrinology
                    products and regularly check in with your patient directly
                  </li>
                </ul>
              </>
            }
          ></IconList>

          <IconList
            IconImgSrc={
              <StaticImage
                src="../../../images/icons/PAMs-communicating-icon.png"
                className="mb-1"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={94}
              />
            }
            ListTitle={
              <>
                <h3>Dedicated PAMs</h3>
              </>
            }
            ListTextArea={
              <>
                <ul>
                  <li>
                    Provide personal support to patients as they take Recorlev
                  </li>
                </ul>
              </>
            }
          ></IconList>

          <IconList
            IconImgSrc={
              <StaticImage
                src="../../../images/icons/truck-icon.png"
                className="mb-1"
                alt=""
                placeholder="blurred"
                layout="constrained"
                width={89}
              />
            }
            ListTitle={
              <>
                <h3>Free courier service</h3>
              </>
            }
            ListTextArea={
              <>
                <ul>
                  <li>
                    Free, convenient, and discreet transport of UFC lab tests
                    available to all patients
                  </li>
                </ul>
              </>
            }
          ></IconList>
        </div>

        <div className="small-12 medium-5 md_pt-2 column text-center show-for-medium">
          <StaticImage
            src="../../../images/patient-laptop-illustration.png"
            alt="Call"
            placeholder="blurred"
            layout="constrained"
            width={500}
          />
        </div>
      </div>

      <div className="row mb-2 md_pt-0 md_pb-2 _contact-shape --support hide-for-large">
        <div className="small-12 medium-6 medium-offset-3 xlarge-4 xlarge-offset-4 column text-center p-relative">
          <StaticImage
            src="../../../images/contact-shape.png"
            alt="contact-shape"
            placeholder="blurred"
            layout="constrained"
            width={618}
          />
          <div className="contact-shape-text p-absolute">
            <p>
              <strong>
                Call <a href="tel:18444447258">1-844-444-RCLV (7258)</a>{" "}
                <span>for support</span>
              </strong>
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="small-12 column">
          <p className="footnote">UFC=urinary free cortisol.</p>
        </div>
      </div>

      <div className="row py-2 lg_pt-1 lg_pb-3">
        <div className="column text-center">
          <Button
            ButtonLink={"/hcp/support/tools-and-resources/"}
            ButtonClass={"primary gtm-cta"}
            ButtonText={
              "Ready to get started? Download everything you need right here"
            }
            ButtonIconAfter={"&nbsp;&gt;"}
            EventLabel={"Download Everything You Need Right Here"}
          ></Button>
        </div>
      </div>
    </Section>
  </Layout>
)

export default RequestARepresentative
