import * as React from "react"

const IconList = props => (
  <div className="row icon-list">
    <div className="small-12 medium-2 column">
      <figure className="h-100 text-center">{props.IconImgSrc}</figure>
    </div>
    <div className="small-12 medium-10 column">
      {props.ListTitle}
      {props.ListTextArea}
    </div>
  </div>
)

export default IconList
