/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Isi from "./template-partials/global-components/isi"
import Footer from "./footer"
import "./layout.scss"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Button from "./template-partials/global-components/buttons"

import { isHCP } from "../helpers/helpers"

import Helmet from "react-helmet"

const LayoutFourOFour = ({ children, location }) => {
  const [currentSiteLink, setCurrentSiteLink] = useState("")
  const [isHcp, setIsHcp] = useState(false)

  const data = useStaticQuery(graphql`
    query FourOhTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            menu {
              name
              link
              subMenu {
                name
                link
              }
            }
          }
          headerMenu {
            name
            menu {
              name
              link
              class
              type
            }
          }
          headerButtons {
            name
            menu {
              name
              link
              class
              type
            }
          }
          footerLinks {
            name
            link
          }
          libCode {
            name
            content {
              code
              date
            }
          }
        }
      }
    }
  `)

  let libCode = data.site.siteMetadata.libCode

  const [currentSite, setCurrentSite] = useState("")
  const [currentLib, setCurrentLib] = useState("")
  const [libDate, setLibDate] = useState("")

  let libs

  useEffect(() => {
    if (isHCP()) {
      setCurrentSite("/hcp")
    } else {
      setCurrentSite("/")
    }

    if (currentSite === "/hcp") {
      libs = libCode[0].content
    } else {
      libs = libCode[1].content
    }

    setCurrentLib(libs[0].code)
    setLibDate(libs[0].date)
  }, [currentSite, libs])

  return (
    <>
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="p3ha895xtbqmdhxm5hi0wvs4tu1jif"
        />
      </Helmet>
      <header>
        <section className="top-bar-nav"></section>

        <section className="logo-bar-nav lg_py-1">
          <div className="row large-uncollapse align-bottom">
            <div className="small-6 large-3 column py-1 d-flex flex-dir-column align-center">
              <Link to={currentSite}>
                <StaticImage
                  id="logo"
                  src="../images/recorlev-logo.png"
                  alt="Links to Homepage of Recorlev Patient Website"
                  placeholder="blurred"
                  layout="constrained"
                  width={261}
                />
              </Link>
            </div>
            <div className="small-6 column hide-for-large text-right"></div>
          </div>
        </section>
      </header>

      <main>{children}</main>

      <Isi inView="inView" />

      <footer>
        <div className="row">
          <div className="small-12 medium-12 column">
            <nav>
              <ul></ul>
            </nav>
          </div>
        </div>
        <div className="row align-bottom">
          <div className="small-12 medium-10 column pb-2 small-order-1 medium-order-1 large-order-1">
            <p>
              Recorlev<sup>&reg;</sup>, Xeris Pharmaceuticals<sup>&reg;</sup>,
              Xeris CareConnection<sup className="trade">&trade;</sup>, and
              their associated logos are trademarks owned by or licensed to
              Xeris Pharmaceuticals, Inc.
              <br />
              PANTHERx Rare Pharmacy is a service mark of PANTHERx Rare, LLC.
              <br />
              Copyright &copy; {new Date().getFullYear()} Xeris Pharmaceuticals,
              Inc. All rights reserved. {currentLib}&nbsp;&nbsp;{libDate}
            </p>
          </div>
          <div className="small-12 medium-2 column small-order-2 py-2 medium-order-1 large-order-2">
            <a
              href="https://www.xerispharma.com/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../images/xeris-pharma-logo.png"
                alt="Xeris Pharmaceuticals, Inc."
                placeholder="blurred"
                layout="constrained"
                width={230}
                height={79}
              />
            </a>
          </div>
        </div>
      </footer>
    </>
  )
}

LayoutFourOFour.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutFourOFour
