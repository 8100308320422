import * as React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"

import icondownload from "../../../images/icons/icon-download-pdf.png"

const Button = props => {
  let iconBefore

  if (props.ButtonIconBefore === "Icondownload") {
    iconBefore =
      '<img class="download-icon" style="height:24px;" src="' +
      icondownload +
      '"/>'
  } else {
    iconBefore = props.ButtonIconBefore
  }

  if (props.type === "anchor") {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        data-gtm-event-label={props.EventLabel}
        dangerouslySetInnerHTML={{
          __html: iconBefore + props.ButtonText + props.ButtonIconAfter,
        }}
        href={props.ButtonLink}
        className={`button ${props.ButtonClass}`}
      ></a>
    )
  } else {
    return (
      <Link
        dangerouslySetInnerHTML={{
          __html: iconBefore + props.ButtonText + props.ButtonIconAfter,
        }}
        to={props.ButtonLink}
        data-gtm-event-label={props.EventLabel}
        className={`button ${props.ButtonClass}`}
      ></Link>
    )
  }
}

Button.defaultProps = {
  ButtonIconBefore: "",
  ButtonIconAfter: "&nbsp;&gt;",
}

Button.propTypes = {
  type: PropTypes.string,
  //ButtonIconBefore: PropTypes.string,
  ButtonText: PropTypes.string,
  ButtonIconAfter: PropTypes.string,
  ButtonLink: PropTypes.string,
  ButtonClass: PropTypes.string,
  EventLabel: PropTypes.string,
}

export default Button
