import React from "react"
import PropTypes from "prop-types"

const Section = props => {
  return (
    <section
      id={props.id}
      className={props.className + ` section`}
      style={props.style}
    >
      {/* keep class naming /\ above as is for overrides in csss */}
      {props.children}
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

Section.defaultProps = {
  className: "section--generic",
  style: {},
}

Section.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Section
