import React, { useEffect, useState } from "react"
import axios from "axios"
import Cookies from "js-cookie"
import moment from "moment"
import { useStaticQuery, graphql } from "gatsby"
import InputMask from "react-input-mask"
import { StaticImage } from "gatsby-plugin-image"

const ContactForm = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [connectTo, setConnectTo] = useState(
    "I'm currently on a medicine for Cushing's"
  )
  const [formReady, setFormReady] = useState(false)
  const [apiError, setApiError] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const client = useStaticQuery(graphql`
    query ClientMetaQuery {
      site {
        siteMetadata {
          stgPatientClientID
          stgPatientClientSecret
          stgPatientEdk
          prdPatientClientID
          prdPatientClientSecret
          prdPatientEdk
          restApi
          authApi
          proxyUrl
        }
      }
    }
  `)

  const checkEnv = () => {
    let windowLoc = window.location.href
    if (
      windowLoc.includes("localhost") ||
      windowLoc.includes("azurewebsites")
    ) {
      return {
        cookie_name: "stg_tk_patient",
        cookie_time: "stg_tme_patient",
        auth: client.site.siteMetadata.authApi,
        rest: client.site.siteMetadata.restApi,
        clientID: client.site.siteMetadata.stgPatientClientID,
        clientSecret: client.site.siteMetadata.stgPatientClientSecret,
        edk: client.site.siteMetadata.stgPatientEdk,
        env_name: "stg",
      }
    } else {
      return {
        cookie_name: "prd_tk_patient",
        cookie_time: "prd_tme_patient",
        auth: client.site.siteMetadata.authApi,
        rest: client.site.siteMetadata.restApi,
        clientID: client.site.siteMetadata.prdPatientClientID,
        clientSecret: client.site.siteMetadata.prdPatientClientSecret,
        edk: client.site.siteMetadata.prdPatientEdk,
        env_name: "prd",
      }
    }
  }

  const measureToken = (onSubmit = false) => {
    //Cookie is set, measure its time — token exp in 10 mins, so you will need a new one
    //measure time now to see if greater than 10 mins
    let envData = checkEnv()
    let currTime = moment()
    let tkTime = Cookies.get(envData.cookie_time)
    let difference = currTime.diff(tkTime, "minutes")
    setFormReady(false)
    if (difference >= 10) {
      //remove old token
      Cookies.remove(envData.cookie_name)
      Cookies.remove(envData.cookie_time)
      console.log("cookie is old get new")
      getToken(onSubmit)
    } else {
      //token is new or ok
      setFormReady(true)
      console.log("cookie ok, carry on")
      if (onSubmit) {
        console.log("was on submit, resubmit (from measure)")
        submitForm()
      }
      return false
    }
  }

  const getToken = (onSubmit = false) => {
    console.log(onSubmit)
    let envData = checkEnv()
    let params = JSON.stringify({
      grant_type: "client_credentials",
      client_id: envData.clientID,
      client_secret: envData.clientSecret,
    })
    if (!Cookies.get(envData.cookie_name)) {
      //no token present, get a new one
      axios
        .post(`${client.site.siteMetadata.proxyUrl}/${envData.auth}`, params, {
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then(function (response) {
          //On success, store the token and timestamp
          Cookies.set(
            envData.cookie_name,
            response.data.access_token,
            { secure: true },
            { sameSite: "strict" }
          )
          Cookies.set(envData.cookie_time, moment())
          setFormReady(true)
          if (onSubmit) {
            console.log("was on submit of new token, resubmit")
            submitForm()
          }
        })
        .catch(function (error) {
          console.log(
            "an error was detected in setting the token for this property: patient-form.js"
          )
          console.log(error)
          setFormReady(false)
          setApiError(true)
        })
    } else {
      //if a token, measure, tokens expire in 10 mins
      measureToken()
    }
  }

  const submitForm = () => {

    let qs = new URL(document.location.toString()).searchParams

    let utm_campaign = qs.get("utm_campaign")
    let utm_medium = qs.get("utm_medium")
    let utm_source = qs.get("utm_source")

    let envData = checkEnv()
    let params = JSON.stringify({
      contactkey: moment().format("x"),
      EventDefinitionKey: envData.edk,
      Data: {
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        ZipCode: zipCode,
        RepresentationStatement: connectTo,
        contact_key: moment().format("x"),
        Source: utm_source,
        Medium: utm_medium,
        Campaign: utm_campaign,
      },
    })
    axios
      .post(`${client.site.siteMetadata.proxyUrl}/${envData.rest}`, params, {
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${Cookies.get(envData.cookie_name)}`,
        },
      })
      .then(function (response) {
        //On success, store the token and timestamp
        setSubmitted(true)
        //FIRE A GTM EVENT HERE
      })
      .catch(function (error) {
        console.log(error)
        console.log(
          "an error was detected in submitting the token or payload for this property: patient-form.js"
        )
        setFormReady(false)
        setApiError(true)
      })
  }

  useEffect(() => {
    getToken()
  }, [])

  useEffect(() => {
    if (submitted === true) {
      if (typeof window !== "undefined") {
        window.dataLayer.push({
          event: "form-submit-success-patient",
        })
      }
    }
  }, [submitted])

  //Show input error messages
  function showError(input, message) {
    let formControl = document.getElementById(input).parentElement.parentElement
    formControl.className = "form-control error"

    let small = document.getElementById(input).nextElementSibling
    small.innerText = message
    //console.log(small);

    let mainError = document.getElementById("all-errors")
    mainError.classList.add("error")

    document.body.scrollTop = 400 // For Safari
    document.documentElement.scrollTop = 400 // For Chrome, Firefox, IE and Opera
  }

  function validateEmail(elementValue) {
    let emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return emailPattern.test(elementValue)
  }

  function validateZipCode(elementValue) {
    let zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/
    return zipCodePattern.test(elementValue)
  }

  function validatePhone(elementValue) {
    //console.log(phone);
    let newPhone = elementValue.replace(/[{()}]/g, "")
    newPhone = newPhone.replace(" ", "")
    newPhone = newPhone.replace("-", "")
    newPhone = newPhone.replace("_", "")

    if (newPhone.length < 12) {
      return false
    } else {
      return true
    }
  }

  function validateInput(elementValue) {
    let pattern = /[a-zA-Z]+/g

    if (elementValue === "") {
      return false
    } else {
      return pattern.test(elementValue)
    }
  }

  //checkRequired fields
  function checkRequired(inputArr) {
    var elems = document.querySelectorAll(".form-control")

    ;[].forEach.call(elems, function (el) {
      el.classList.remove("error")
    })

    let mainError = document.getElementById("all-errors")
    mainError.classList.remove("error")

    if (!validateInput(firstName)) {
      let target = "firstName"
      let message = "Enter a valid first name."
      showError(target, message)
    }

    if (!validateInput(lastName)) {
      let target = "lastName"
      let message = "Enter a valid last name."
      showError(target, message)
    }

    if (!validateEmail(email)) {
      let target = "email"
      let message = "Enter a valid email."
      showError(target, message)
    }

    if (!validatePhone(phone)) {
      let target = "phone"
      let message = "Enter a valid phone number."
      showError(target, message)
    }

    if (!validateZipCode(zipCode)) {
      let target = "zipCode"
      let message = "Enter a valid zip code."
      showError(target, message)
    }

    /*if (!validateInput(connectTo)) {
      let target = 'connectTo';
      let message = "Please select one"
      showError(target, message);
    }*/

    let formComplete = true
    ;[].forEach.call(elems, function (el) {
      if (el.classList.contains("error")) {
        formComplete = false
      }
    })

    if (formComplete === true) {
      let envData = checkEnv()
      if (Cookies.get(envData.cookie_name)) {
        console.log("measuring token to see expire")
        measureToken(true)
      } else {
        setFormReady(false)
        setApiError(true)
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    checkRequired()
  }

  return (
    <>
      {submitted && (
        <>
          <div id="thank-you-patient" className="row md_py-2 lg_py-1">
            <div className="small-12 medium-8 medium-offset-2 large-6 large-offset-3 column text-center">
              <h2>Thank you for signing up.</h2>
            </div>
          </div>
          <div className="row py-1">
            <div className="small-12 column text-center">
              <h3>
                You will receive an email from your Patient Access Manager (PAM)
                <br />
                with more information very soon.
              </h3>
            </div>
          </div>
          <div className="row md_py-2 lg_py-1">
            <div className="columns small-12 text-center">
              <a
                href="tel:+18444447257"
                className="gtm-link-internal d-inline-block"
                data-gtm-event-label="Call for more support at 1-844-444-RCLV (7258)"
              >
                <StaticImage
                  src="../../../images/patient-support-thank-you-shape.png"
                  alt="Xeris Care Connection"
                  placeholder="blurred"
                  layout="constrained"
                  width={419}
                />
              </a>
            </div>
          </div>
        </>
      )}
      {!submitted && (
        <>
          {!formReady && (
            <>
              {apiError && (
                <p>
                  A form error occurred. Please refresh this page and try again.
                </p>
              )}
              {!apiError && <p>Loading webform...</p>}
            </>
          )}
          {formReady && (
            <>
              <div className="row">
                <div className="small-12 column">
                  <h2>
                    Sign up to meet your partner in care and receive treatment
                    and Cushing's support, along with personalized wellness
                    information. A Patient Access Manager (PAM) will reach out
                    to get you started.
                  </h2>
                </div>
              </div>
              <p>
                All fields required.
                <br />
                <small id="all-errors">
                  Please fix the errors and resubmit.
                </small>
              </p>

              <form id="form">
                <div className="row">
                  <div className="columns small-12 large-6">
                    <div className="form-control">
                      <label>
                        First name
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          onChange={event => setFirstName(event.target.value)}
                          onKeyDown={event => setFirstName(event.target.value)}
                          className="gtm-form"
                          data-gtm-event-label="First Name"
                        />
                        <small>Error Message</small>
                      </label>
                    </div>
                    <div className="form-control">
                      <label>
                        Last name
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          onChange={event => setLastName(event.target.value)}
                          onKeyDown={event => setLastName(event.target.value)}
                          className="gtm-form"
                          data-gtm-event-label="Last Name"
                        />
                        <small>Error Message</small>
                      </label>
                    </div>
                    <div className="form-control">
                      <label>
                        Email
                        <input
                          type="text"
                          name="email"
                          id="email"
                          onChange={event => setEmail(event.target.value)}
                          onKeyDown={event => setEmail(event.target.value)}
                          className="gtm-form"
                          data-gtm-event-label="Email"
                        />
                        <small>Error Message</small>
                      </label>
                    </div>
                    <div className="form-control">
                      <label>
                        Phone
                        <br />
                        <InputMask
                          type="tel"
                          name="phone"
                          id="phone"
                          value={phone}
                          mask="+1\(999) 999-9999"
                          maskchar=" "
                          onChange={event => setPhone(event.target.value)}
                          onKeyDown={event => setPhone(event.target.value)}
                          className="gtm-form"
                          data-gtm-event-label="Phone"
                        />
                        <small>Error Message</small>
                      </label>
                    </div>
                    <div className="form-control">
                      <label>
                        Zip code
                        <input
                          type="text"
                          name="zipCode"
                          id="zipCode"
                          onChange={event => setZipCode(event.target.value)}
                          onKeyDown={event => setZipCode(event.target.value)}
                          className="gtm-form"
                          data-gtm-event-label="Zip Code"
                        />
                        <small>Error Message</small>
                      </label>
                    </div>
                    <div className="form-control">
                      <label>
                        How would you best describe yourself?
                        <br />
                        <select
                          name="connectTo"
                          id="connectTo"
                          onChange={event => setConnectTo(event.target.value)}
                          onKeyDown={event => setConnectTo(event.target.value)}
                        >
                          <option
                            value="I'm currently on a medicine for Cushing's"
                            className="gtm-form"
                            data-gtm-event-label="I'm currently on a medicine for Cushing's"
                          >
                            I’m currently on a medicine for Cushing’s
                          </option>
                          <option
                            value="I've been diagnosed with Cushing’s but am not on a medicine"
                            className="gtm-form"
                            data-gtm-event-label="I've been diagnosed with Cushing's but am not on a medicine"
                          >
                            I’ve been diagnosed with Cushing’s but am not on a
                            medicine
                          </option>
                          <option
                            value="I've been diagnosed with Cushing's and have been prescribed Recorlev"
                            className="gtm-form"
                            data-gtm-event-label="I've been diagnosed with Cushing's and have been prescribed Recorlev"
                          >
                            I've been diagnosed with Cushing's and have been
                            prescribed Recorlev
                          </option>
                          <option
                            value="I'm seeking information for a family member or friend"
                            className="gtm-form"
                            data-gtm-event-label="I'm seeking information for a family member or friend"
                          >
                            I’m seeking information for a family member or
                            friend
                          </option>
                        </select>
                        <small>Error Message</small>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="columns small-12 large-8">
                    <p>
                      By submitting, I understand I am giving
                      Xeris&nbsp;Pharmaceuticals<sup>&reg;</sup>, its
                      affiliates, and business partners permission to use the
                      personal information provided in this registration form to
                      contact me by the following methods, but not limited to:
                      mail, email, telephone call, or text about disease and
                      product information, disease or product-related events,
                      support services, market research, and to share other
                      promotional and marketing information. By submitting this
                      form, I consent to these uses and agree to the
                      Xeris&nbsp;Pharmaceuticals{" "}
                      <a
                        href="https://www.xerispharma.com/privacy-policy"
                        target="_blank"
                        className="text-underline gtm-link-external"
                        data-gtm-event-label="Privacy Policy"
                        rel="noreferrer"
                      >
                        Privacy Statement
                      </a>
                      . I understand I can opt out by clicking on the
                      unsubscribe link in future communications or by sending a
                      letter with my full contact information (eg, name,
                      address, email address, phone number, etc) to
                      Xeris&nbsp;CareConnection<sup>™</sup> Patient Support
                      Services, 1375 W Fulton Street, Suite 1300, Chicago, IL
                      60607.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="columns small-12">
                    <button
                      className="button gtm-cta"
                      aria-label="button"
                      type="button"
                      data-gtm-event-label="Submit"
                      onClick={handleSubmit}
                    >
                      LET'S CHAT &gt;
                    </button>
                  </div>
                </div>
              </form>
              <div className="row text-center align-center pt-3 pb-1">
                <div className="small-12 medium-12 large-5 column pt-0 md_py-2 _contact-shape --support">
                  <div className="p-relative text-center">
                    <StaticImage
                      src="../../../images/call-for-support-text-box.png"
                      alt="Have more questions? Call for support at 1-844-444-RCLV (7258)"
                      placeholder="blurred"
                      layout="constrained"
                      width={618}
                    />
                    <div className="contact-shape-text p-absolute md_pt-0">
                      <p>
                        <strong>
                          Have more questions?
                          <br /> Call for more support at
                          <br />{" "}
                          <a
                            href="tel:18444447258"
                            className="gtm-link-internal"
                            data-gtm-event-label="Call for more support at 1-844-444-RCLV (7258)"
                          >
                            1-844-444-RCLV (7258)
                          </a>
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default ContactForm
