import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import axios from "axios"
import Cookies from "js-cookie"
import moment from "moment"

const UnsubscribePageHcp = () => {
  const [hasError, setHasError] = useState(false)
  const [emailAddress, setEmailAddress] = useState("no")
  const [formReady, setFormReady] = useState(false)
  const [apiError, setApiError] = useState(false)
  const [submitted, setSubmitted] = useState("")

  const client = useStaticQuery(graphql`
    query hcpUnsubQuery {
      site {
        siteMetadata {
          stgHCPClientID
          stgHCPClientSecret
          stgHCPEdk
          prdHCPClientID
          prdHCPClientSecret
          prdHCPEdk
          restApi
          authApi
          proxyUrl
          patientUnsubEdkStg
          HCPUnsubEdkStg
          patientUnsubEdkPrd
          HCPUnsubEdkPrd
        }
      }
    }
  `)

  const checkEnv = () => {
    let windowLoc = window.location.href
    if (
      windowLoc.includes("localhost") ||
      windowLoc.includes("azurewebsites")
    ) {
      return {
        cookie_name: "stg_ustk_hcp",
        cookie_time: "stg_ustme_hcp",
        auth: client.site.siteMetadata.authApi,
        rest: client.site.siteMetadata.restApi,
        clientID: client.site.siteMetadata.stgHCPClientID,
        clientSecret: client.site.siteMetadata.stgHCPClientSecret,
        edk: client.site.siteMetadata.HCPUnsubEdkStg,
        env_name: "stg",
      }
    } else {
      return {
        cookie_name: "prd_ustk_hcp",
        cookie_time: "prd_ustme_hcp",
        auth: client.site.siteMetadata.authApi,
        rest: client.site.siteMetadata.restApi,
        clientID: client.site.siteMetadata.prdHCPClientID,
        clientSecret: client.site.siteMetadata.prdHCPClientSecret,
        edk: client.site.siteMetadata.HCPUnsubEdkPrd,
        env_name: "prd",
      }
    }
  }

  const measureToken = (onSubmit = false) => {
    //Cookie is set, measure its time — token exp in 10 mins, so you will need a new one
    //measure time now to see if greater than 10 mins
    let envData = checkEnv()
    let currTime = moment()
    let tkTime = Cookies.get(envData.cookie_time)
    let difference = currTime.diff(tkTime, "minutes")
    setFormReady(false)
    if (difference >= 10) {
      //remove old token
      Cookies.remove(envData.cookie_name)
      Cookies.remove(envData.cookie_time)
      console.log("cookie is old get new")
      getToken(onSubmit)
    } else {
      //token is new or ok
      setFormReady(true)
      console.log("cookie ok, carry on")
      if (onSubmit) {
        console.log("was on submit, resubmit (from measure)")
        submitForm()
      }
      return false
    }
  }

  const getToken = (onSubmit = false) => {
    console.log(onSubmit)
    let envData = checkEnv()
    let params = JSON.stringify({
      grant_type: "client_credentials",
      client_id: envData.clientID,
      client_secret: envData.clientSecret,
    })
    if (!Cookies.get(envData.cookie_name)) {
      //no token present, get a new one
      axios
        .post(`${client.site.siteMetadata.proxyUrl}/${envData.auth}`, params, {
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then(function (response) {
          //On success, store the token and timestamp
          Cookies.set(
            envData.cookie_name,
            response.data.access_token,
            { secure: true },
            { sameSite: "strict" }
          )
          Cookies.set(envData.cookie_time, moment())
          setFormReady(true)
          if (onSubmit) {
            console.log("was on submit of new token, resubmit")
            submitForm()
          }
        })
        .catch(function (error) {
          console.log(
            "an error was detected in setting the token for this property: HCP unsubscribe.js"
          )
          console.log(error)
          setFormReady(false)
          setApiError(true)
        })
    } else {
      //if a token, measure, tokens expire in 10 mins
      measureToken()
    }
  }

  //On Success
  const submitForm = () => {
    let envData = checkEnv()
    let params = JSON.stringify({
      contactkey: moment().format("x"),
      EventDefinitionKey: envData.edk,
      Data: {
        EmailAddress: emailAddress,
        booleanOptInDate: moment().format("MMMM Do YYYY, h:mm:ss a"),
        datetime: moment().format(),
        contact_key: moment().format("x"),
      },
    })
    axios
      .post(`${client.site.siteMetadata.proxyUrl}/${envData.rest}`, params, {
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${Cookies.get(envData.cookie_name)}`,
        },
      })
      .then(function (response) {
        //On success, store the token and timestamp
        setSubmitted(true)
        //FIRE A GTM EVENT HERE
      })
      .catch(function (error) {
        console.log(error)
        console.log(envData.edk)
        console.log(envData.clientID)
        console.log(envData.clientSecret)
        console.log(params)
        console.log(
          "an error was detected in submitting the token or payload for this property: HCP unsubscribe.js"
        )
        setFormReady(false)
        setApiError(true)
      })
  }

  const validateEmail = email => {
    let emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailPattern.test(email)
  }

  const verifySubmit = e => {
    let email = document.getElementById("unsub-email").value
    if (email.length !== 0) {
      if (validateEmail(email)) {
        setHasError(false)
        setEmailAddress(email)
      } else {
        setHasError(true)
      }
    } else {
      setHasError(true)
    }
    e.preventDefault()
  }

  useEffect(() => {
    getToken()
  }, [])

  useEffect(() => {
    if (emailAddress !== "no") {
      console.log(emailAddress)
      let envData = checkEnv()
      if (Cookies.get(envData.cookie_name)) {
        console.log("measuring token to see expire")
        measureToken(true)
      } else {
        setFormReady(false)
        setApiError(true)
      }
    }
  }, [emailAddress])

  return (
    <Layout>
      <Seo title="Unsubscribe" />
      <Section className="interior-page">
        <div className="row pt-2 lg_pt-2">
          <div className="small-12 column">
            {!submitted && (
              <>
                <h1>Unsubscribe</h1>
                <p className="unsub">
                  We're sorry to see you go! To stop receiving emails from Xeris
                  about{" "}
                  <strong>
                    Recorlev<sup className="reg">®</sup>
                  </strong>
                  , please enter your email address below.
                </p>
              </>
            )}
            {submitted && (
              <>
                <h1>Thanks</h1>
                <p className="unsub">
                  <strong>{emailAddress}</strong> is now successfully
                  unsubscribed from the Xeris Recorlev<sup>®</sup> mailing list.
                  You will stop receiving emails within the next 10 business
                  days.
                </p>
              </>
            )}
          </div>
        </div>
        {!submitted && (
          <div className="row lg_mt-0 mt-0 lg_pb-2 pb-2">
            <div className="small-12 large-6 columns">
              <>
                {formReady && (
                  <form>
                    {/* <label htmlFor="unsub-email">Please enter your email address</label> */}
                    <input
                      id="unsub-email"
                      type="email"
                      required
                      placeholder="Please enter your email address"
                    />
                    {hasError && (
                      <div className="form-control error">
                        <small>
                          <strong>Enter a valid email.</strong>
                        </small>
                      </div>
                    )}
                    <button
                      onClick={e => verifySubmit(e)}
                      className="button lg_mt-1 mt-1"
                      aria-label="button"
                      type="submit"
                    >
                      UNSUBSCRIBE &gt;
                    </button>
                  </form>
                )}
                {!formReady && (
                  <>
                    {apiError && (
                      <p>
                        A form error occurred. Please refresh this page and try
                        again.
                      </p>
                    )}
                    {!apiError && <p>Loading webform...</p>}
                  </>
                )}
              </>
            </div>
          </div>
        )}
      </Section>
    </Layout>
  )
}

export default UnsubscribePageHcp
