import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { isIOS } from "react-device-detect"
import Video from "./video"

const CheckBrowser = () => {
  console.log("Checker loaded")
  if (isIOS) {
    return (
      <StaticImage
        className=""
        src="../../../images/hcp-hero-flowers.png"
        alt="Cortisol Lowering is Only the Beginning of Her Story"
        placeholder="blurred"
        layout="constrained"
        width={557}
      />
    )
  } else {
    return <Video />
  }
}

export default CheckBrowser
